import React from 'react';
import ReactDOM from 'react-dom';
import style from './modal.module.css';

const modalRoot = document.getElementById('modal-root');

class CcModal extends React.Component {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
    this.el.id = props.id;
    this.el.classList.add('modal-holder');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  onBackdropClick(ev) {
    if (ev.target.classList.contains(style.modal) && this.props.backdropClose && this.props.handleClose) {
      this.props.handleClose(ev);
    }
  }

  render() {
    let cssClass = style.modal;
    
    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    if (this.props.show) {
      cssClass += (' ' + style.show);
    }

    if (this.props.backdropClose && this.props.handleClose) {
      cssClass += (' ' + style.backdropCloseable);
    }

    return ReactDOM.createPortal(
      <div className={cssClass} onClick={(ev) => { this.onBackdropClick(ev, this.props) }}>
        <div className={style.dialog}>
          <div className={style.content}>
            <i className={'cticon-close ' + style.iconClose} onClick={this.props.handleClose}></i>
            {this.props.title && <h2>{this.props.title}</h2>}
            <div className={style.container}>
              <div>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>,
      this.el
    );
  }
}

export default CcModal;