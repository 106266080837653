import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcButton from '../components/form/button';

import style from './contract.module.css';

class Contract extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="video-auth" />
        <main className="main">
          <div className={style.container}>
            <h1>{t('contract.pageTitle')}</h1>
            <h2>{t('contract.pageSubtitle')}</h2>
            <div className="content">
              <div className={style.btnHolder}>
                <CcButton text={t('contract.forward')} link="congratulation" />
              </div>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Contract);