import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcPanel from '../components/common/panel';
import CcModal from '../components/common/modal';
import CcButton from '../components/form/button';

import Platform from '../screens/platform';

import style from './confirm.module.css';

class Confirm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modals: {}
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal(ev) {
    const link = ev.target.closest('a');

    ev.preventDefault();

    if (link.dataset.modal) {
      this.state.modals[link.dataset.modal] = { show: true };
      this.setState(this.state.modals);
    }
  }

  hideModal(ev) {
    const modalId = ev.target.closest('.modal-holder').id;

    ev.preventDefault();
    ev.stopPropagation();

    if (modalId) {
      this.state.modals[modalId] = { show: false };
      this.setState(this.state.modals);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="traderscard" step="12" />
        <main className="main">
          <div className="container">
            <h1>{t('confirm.pageTitle')}</h1>
            <h2>{t('confirm.pageSubtitle')}</h2>
            <div className="content">

              <div className={style.platform}>
                <div className={style.platformName}>
                  <div>
                    <strong>{t('confirm.selectedPlatform')}: </strong>
                    { t('platform.' + (localStorage.getItem('ct-platform') || 'none')) }
                  </div>
                </div>
                <a href="platform" className={style.edit} onClick={this.showModal} data-modal="genericModal">
                  <i className="cticon-pencil" />
                </a>
              </div>

              <CcPanel title={t('confirm.yourData')} expanded>
                <div className={style.cats}>
                  <div>
                    <h4>
                      {t('contactInfo.pageTitle')}
                      <a href="contact-info" className={style.edit}>
                        <i className="cticon-pencil" />
                      </a>
                    </h4>
                    <div className={style.groups}>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.title')}</span>
                          Mr
                        </div>
                        <div>
                          <span>{t('contactInfo.firstName')}</span>
                          Alex
                        </div>
                        <div>
                          <span>{t('contactInfo.lastName')}</span>
                          Johnson
                        </div>
                      </div>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.email')}</span>
                          alex@gmail.com
                        </div>
                        <div>
                          <span>{t('contactInfo.phoneNr')}</span>
                          +41 76 123 45 67
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>
                      {t('personalInfo.pageTitle')}
                      <a href="personal-info" className={style.edit}>
                        <i className="cticon-pencil" />
                      </a>
                    </h4>
                    <div className={style.groups}>
                      <div className={style.items}>
                        <div>
                          <span>{t('personalInfo.birthDate')}</span>
                          01.01.2000
                        </div>
                        <div>
                          <span>{t('personalInfo.birthCountry')}</span>
                          Switzerland
                        </div>
                      </div>
                      <div className={style.items}>
                        <div>
                          <span>{t('personalInfo.nationality')}</span>
                          Switzerland
                        </div>
                        <div>
                          <span>{t('personalInfo.maritalStatus')}</span>
                          Married
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>
                      {t('personalInfo.residenceAddress')}
                      <a href="personal-info" className={style.edit}>
                        <i className="cticon-pencil" />
                      </a>
                    </h4>
                    <div className={style.groups}>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.country')}</span>
                          Switzerland
                        </div>
                        <div>
                          <span>{t('contactInfo.postCode')}</span>
                          5400
                        </div>
                      </div>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.city')}</span>
                          Baden
                        </div>
                        <div>
                          <span>{t('contactInfo.streetAndNumber')}</span>
                          Weite Gasse 11
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CcPanel>

              <CcPanel title={t('declarations.pageTitle')} expandable>
                <div className={style.cats}>
                  <div>
                    <h4>
                      {t('declarations.beneficialOwner')}
                      <a href="declarations" className={style.edit}>
                        <i className="cticon-pencil" />
                      </a>
                    </h4>
                    <div className={style.groups}>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.firstName')}</span>
                          Alex
                        </div>
                        <div>
                          <span>{t('contactInfo.lastName')}</span>
                          Johnson
                        </div>
                        <div>
                          <span>{t('personalInfo.birthDate')}</span>
                          01.01.2000
                        </div>
                        <div>
                          <span>{t('personalInfo.nationality')}</span>
                          Switzerland
                        </div>
                        <div>
                          <span>{t('personalInfo.birthCountry')}</span>
                          Switzerland
                        </div>
                        <div>
                          <span>{t('personalInfo.birthCity')}</span>
                          Switzerland
                        </div>
                      </div>
                      <div className={style.items}>
                        <div>
                          <span>{t('contactInfo.country')}</span>
                          Switzerland
                        </div>
                        <div>
                          <span>{t('contactInfo.city')}</span>
                          Baden
                        </div>
                        <div>
                          <span>{t('contactInfo.postCode')}</span>
                          5400
                        </div>
                        <div>
                          <span>{t('contactInfo.streetAndNumber')}</span>
                          Weite Gasse 11
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>
                      {t('confirm.politicalExposure')}
                      <a href="declarations" className={style.edit}>
                        <i className="cticon-pencil" />
                      </a>
                    </h4>
                    <div className={style.groups}>
                      <div className={style.items}>
                        <div>
                          <span>{t('confirm.politicalExposed')}</span>
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CcPanel>

              <div className={style.btnHolder}>
                <CcButton text={t('confirm.cta')} link="video-auth" />
              </div>
            </div>
          </div>
        </main>
        <CcFooter />

        <CcModal
          id="genericModal"
          show={this.state.modals['genericModal']?.show}
          handleClose={this.hideModal}
          backdropClose={true}
          customCssClass={style.genericModal}
        >
          <Platform modal />
        </CcModal>
        
      </>
    );
  }
}

export default withTranslation()(Confirm);