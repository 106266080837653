import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcButton from '../components/form/button';

import style from './congratulation.module.css';

class Congratulation extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="/" />
        <main className="main">
          <div className="container">
            <h1>{t('congratulation.pageTitle')}</h1>
            <h2>{t('congratulation.pageSubtitle')}</h2>
            <div className="content">
              <div className={style.referral}>
                <h3>{t('congratulation.discoverReward')}</h3>
                <Trans i18nKey="congratulation.recommend" components={{ B: <strong /> }} />
                <div className={style.btnHolder}>
                  <CcButton cssClass="secondary" text={t('congratulation.discover')} link="/" />
                </div>
              </div>
              <div className={style.backHome}>
                <a href="/">{t('common.backHome')}</a>
              </div>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Congratulation);