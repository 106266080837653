import React from 'react';
import style from './radio.module.css';

class CcRadio extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      selection: props.value
    };

    this.containerRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(ev) {
    this.setState(
      { selection: ev.target.value },
      () => {
        if (this.props.onChange) {
          this.props.onChange(ev);
        }

        // Wait until the next JS tick to allow any onChange re-render tasks to be executed before focusing the selected radio item
        setTimeout(() => this.containerRef.current.querySelectorAll(':scope > label[class^="radio_selected__"], :scope > label[class*=" radio_selected__"]')[0].focus(), 1);
      }
    );
  }
  
  render() {
    const Options = () => {
      let cssClass = style.field;

      if (this.props.cssClass) {
        cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
      }

      if (this.props.customCssClass) {
        cssClass += (' ' + this.props.customCssClass);
      }

      let fields = this.props.options.map((option, i) => {
        const [text, value, hint] = (typeof option === 'string' ? [option, i] : option);
        const checked = (this.state.selection == value);
        
        return (
          <label key={i} className={checked ? style.selected : ''}>
            {text}
            {hint && <span className={style.hint}>{hint}</span>}
            <input type="radio"
              name={this.props.name}
              checked={checked}
              onChange={this.handleChange}
              value={value}
            />
            <span className={style.icon}></span>
          </label>
        );
      });
      
      return (
        <div className={cssClass} ref={this.containerRef}>
          {fields}
        </div>
      )
    };
    
    return (
      <Options />
    );
  }
}

export default CcRadio;
