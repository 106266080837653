import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcRadio from '../components/form/radio';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcButton from '../components/form/button';

import style from './occupation.module.css';

class Occupation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      occupation: null
    };

    this.changePosition = this.changePosition.bind(this);
  }

  changePosition(ev) {
    this.setState({
      occupation: ev.target.value,
      prevEmployed: null,
      employedStudent: null
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="declarations" showPlatform={this.props.showPlatform} step="7" />
        <main className="main">
          <form autoComplete="off">
            <section>
              <div className="container">
                <h1>{t('occupation.pageTitle')}</h1>
                <h2>{t('occupation.pageSubtitle')}</h2>
                <div className="content">
                  <h3>{t('occupation.current')}</h3>
                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcRadio
                          name="position"
                          options={[
                            [t('occupation.employed'), 'employed'],
                            [t('occupation.selfEmployed'), 'self-employed'],
                            [t('occupation.unemployed'), 'unemployed'],
                            [t('occupation.student'), 'student'],
                            [t('occupation.retired'), 'retired']
                          ]}
                          customCssClass={style.occupation}
                          onChange={this.changePosition}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>

                    {
                      this.state.occupation === 'employed' &&
                      <>
                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcSelectBox
                              name="sector"
                              label={t('occupation.sector')}
                              items={[
                                'Advice/consulting',
                                'Accounting',
                                'Agriculture',
                                'Aircraft',
                                'Arms and nuclear tech',
                                'Art and antiques',
                                'Automobile',
                                'Banking/financial services',
                                'Car/boat/airplane dealing',
                                'Chemistry/pharma',
                                'Commerce/sales',
                                'Construction',
                                'Craft',
                                'Creative/design',
                                'Education',
                                'Engineering/architecture',
                                'Entertainment/media',
                                'Finance/economy',
                                'Food/beverage',
                                'Gambling',
                                'Health/medicine',
                                'Human resources',
                                'Informatics',
                                'Insurance',
                                'Marketing',
                                'Money exchange/transfer',
                                'Precious and jewelry',
                                'Publish services',
                                'Real estate',
                                'Research and development',
                                'Security',
                                'Transport',
                                'Tax/legal',
                                'Telecommunication',
                                'Textile',
                                'Tourism',
                                'Other'
                              ]}
                            />
                          </CcFieldGroup>
                          <CcFieldGroup>
                            <CcInput name="occupation" label={t('occupation.occupation')} />
                          </CcFieldGroup>
                        </CcFieldRow>
                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcInput name="role" label={t('occupation.role')} />
                          </CcFieldGroup>
                        </CcFieldRow>
                      </>
                    }

                    {
                      this.state.occupation === 'self-employed' &&
                      <>
                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcSelectBox
                              name="sector"
                              label={t('occupation.sector')}
                              items={[
                                'Advice/consulting',
                                'Accounting',
                                'Agriculture',
                                'Aircraft',
                                'Arms and nuclear tech',
                                'Art and antiques',
                                'Automobile',
                                'Banking/financial services',
                                'Car/boat/airplane dealing',
                                'Chemistry/pharma',
                                'Commerce/sales',
                                'Construction',
                                'Craft',
                                'Creative/design',
                                'Education',
                                'Engineering/architecture',
                                'Entertainment/media',
                                'Finance/economy',
                                'Food/beverage',
                                'Gambling',
                                'Health/medicine',
                                'Human resources',
                                'Informatics',
                                'Insurance',
                                'Marketing',
                                'Money exchange/transfer',
                                'Precious and jewelry',
                                'Publish services',
                                'Real estate',
                                'Research and development',
                                'Security',
                                'Transport',
                                'Tax/legal',
                                'Telecommunication',
                                'Textile',
                                'Tourism',
                                'Other'
                              ]}
                            />
                          </CcFieldGroup>
                          <CcFieldGroup>
                            <CcInput name="activity" label={t('occupation.activityType')} />
                          </CcFieldGroup>
                        </CcFieldRow>
                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcInput name="role" label={t('occupation.selfRole')} />
                          </CcFieldGroup>
                        </CcFieldRow>
                      </>
                    }

                  </CcFieldSet>
                </div>
              </div>
            </section>

            {
              this.state.occupation === 'employed' &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t('occupation.yourCompany')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-name" label={t('occupation.companyName')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup customCssClass={style.smFieldGroup}>
                          <CcSelectBox
                            name="company-country"
                            label={t('contactInfo.country')}
                            items={[
                              { label: 'Switzerland', value: 'CH' },
                              { label: 'Austria', value: 'AT' },
                              { label: 'Germany', value: 'DE' },
                              { label: 'Denmark', value: 'DK' },
                              { label: 'United Kingdom', value: 'UK' }
                            ]}
                          />
                        </CcFieldGroup>
                        <CcFieldGroup>
                          <CcInput name="company-city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                            <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                          </CcInput>
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-street" label={t('contactInfo.streetAndNumber')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }

            {
              this.state.occupation === 'self-employed' &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t('occupation.yourCompany')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-name" label={t('occupation.companyName')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup customCssClass={style.smFieldGroup}>
                          <CcSelectBox
                            name="company-country"
                            label={t('contactInfo.country')}
                            items={[
                              { label: 'Switzerland', value: 'CH' },
                              { label: 'Austria', value: 'AT' },
                              { label: 'Germany', value: 'DE' },
                              { label: 'Denmark', value: 'DK' },
                              { label: 'United Kingdom', value: 'UK' }
                            ]}
                          />
                        </CcFieldGroup>
                        <CcFieldGroup>
                          <CcInput name="company-city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                            <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                          </CcInput>
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-street" label={t('contactInfo.streetAndNumber')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }

            {
              (this.state.occupation === 'unemployed' || this.state.occupation === 'retired') &&
              <>
                <section className="darker">
                  <div className="container">
                    <div className="content">
                      <h3>{t('occupation.previouslyEmployed')}</h3>
                      <CcFieldSet>
                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcRadio
                              name="previously-employed"
                              options={[
                                [t('occupation.employed'), 'employed'],
                                [t('occupation.selfEmployed'), 'self-employed'],
                                [t('common.none'), 'none']
                              ]}
                              onChange={ev => this.setState({ prevEmployed: ev.target.value })}
                            />
                          </CcFieldGroup>
                        </CcFieldRow>
                        {
                          this.state.prevEmployed && this.state.prevEmployed !== 'none' &&
                          <>
                            <CcFieldRow>
                              <CcFieldGroup>
                                <CcSelectBox
                                  name="sector"
                                  label={t('occupation.sector')}
                                  items={[
                                    'Advice/consulting',
                                    'Accounting',
                                    'Agriculture',
                                    'Aircraft',
                                    'Arms and nuclear tech',
                                    'Art and antiques',
                                    'Automobile',
                                    'Banking/financial services',
                                    'Car/boat/airplane dealing',
                                    'Chemistry/pharma',
                                    'Commerce/sales',
                                    'Construction',
                                    'Craft',
                                    'Creative/design',
                                    'Education',
                                    'Engineering/architecture',
                                    'Entertainment/media',
                                    'Finance/economy',
                                    'Food/beverage',
                                    'Gambling',
                                    'Health/medicine',
                                    'Human resources',
                                    'Informatics',
                                    'Insurance',
                                    'Marketing',
                                    'Money exchange/transfer',
                                    'Precious and jewelry',
                                    'Publish services',
                                    'Real estate',
                                    'Research and development',
                                    'Security',
                                    'Transport',
                                    'Tax/legal',
                                    'Telecommunication',
                                    'Textile',
                                    'Tourism',
                                    'Other'
                                  ]}
                                />
                              </CcFieldGroup>
                              <CcFieldGroup>
                                <CcInput name="occupation" label={t('occupation.occupation')} />
                              </CcFieldGroup>
                            </CcFieldRow>
                            <CcFieldRow>
                              <CcFieldGroup>
                                <CcInput name="role" label={t('occupation.role')} />
                              </CcFieldGroup>
                            </CcFieldRow>
                          </>
                        }
                      </CcFieldSet>
                    </div>
                  </div>
                </section>
                {
                  this.state.prevEmployed && this.state.prevEmployed !== 'none' &&
                  <section>
                    <div className="container">
                      <div className="content">
                        <h3>{t('occupation.yourCompany')}</h3>
                        <CcFieldSet>
                          <CcFieldRow>
                            <CcFieldGroup>
                              <CcInput name="company-name" label={t('occupation.companyName')} />
                            </CcFieldGroup>
                          </CcFieldRow>
                          <CcFieldRow>
                            <CcFieldGroup customCssClass={style.smFieldGroup}>
                              <CcSelectBox
                                name="company-country"
                                label={t('contactInfo.country')}
                                items={[
                                  { label: 'Switzerland', value: 'CH' },
                                  { label: 'Austria', value: 'AT' },
                                  { label: 'Germany', value: 'DE' },
                                  { label: 'Denmark', value: 'DK' },
                                  { label: 'United Kingdom', value: 'UK' }
                                ]}
                              />
                            </CcFieldGroup>
                            <CcFieldGroup>
                              <CcInput name="company-city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                                <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                              </CcInput>
                            </CcFieldGroup>
                          </CcFieldRow>
                          <CcFieldRow>
                            <CcFieldGroup>
                              <CcInput name="company-street" label={t('contactInfo.streetAndNumber')} />
                            </CcFieldGroup>
                          </CcFieldRow>
                        </CcFieldSet>
                      </div>
                    </div>
                  </section>
                }
              </>
            }

            {
              this.state.occupation === 'student' &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t('occupation.currEmployedOrIntership')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="currently-employed"
                            options={[
                              [t('common.yes'), 'yes'],
                              [t('common.no'), 'no']
                            ]}
                            onChange={ev => this.setState({ employedStudent: ev.target.value })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                      {
                        this.state.employedStudent === 'yes' &&
                        <>
                          <CcFieldRow>
                            <CcFieldGroup>
                              <CcSelectBox
                                name="sector"
                                label={t('occupation.sector')}
                                items={[
                                  'Advice/consulting',
                                  'Accounting',
                                  'Agriculture',
                                  'Aircraft',
                                  'Arms and nuclear tech',
                                  'Art and antiques',
                                  'Automobile',
                                  'Banking/financial services',
                                  'Car/boat/airplane dealing',
                                  'Chemistry/pharma',
                                  'Commerce/sales',
                                  'Construction',
                                  'Craft',
                                  'Creative/design',
                                  'Education',
                                  'Engineering/architecture',
                                  'Entertainment/media',
                                  'Finance/economy',
                                  'Food/beverage',
                                  'Gambling',
                                  'Health/medicine',
                                  'Human resources',
                                  'Informatics',
                                  'Insurance',
                                  'Marketing',
                                  'Money exchange/transfer',
                                  'Precious and jewelry',
                                  'Publish services',
                                  'Real estate',
                                  'Research and development',
                                  'Security',
                                  'Transport',
                                  'Tax/legal',
                                  'Telecommunication',
                                  'Textile',
                                  'Tourism',
                                  'Other'
                                ]}
                              />
                            </CcFieldGroup>
                            <CcFieldGroup>
                              <CcInput name="occupation" label={t('occupation.occupation')} />
                            </CcFieldGroup>
                          </CcFieldRow>
                          <CcFieldRow>
                            <CcFieldGroup>
                              <CcInput name="role" label={t('occupation.internRole')} />
                            </CcFieldGroup>
                          </CcFieldRow>
                        </>
                      }
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }

            {
              this.state.occupation === 'student' && this.state.employedStudent === 'yes' &&
              <section>
                <div className="container">
                  <div className="content">
                    <h3>{t('occupation.yourCompany')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-name" label={t('occupation.companyName')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup customCssClass={style.smFieldGroup}>
                          <CcSelectBox
                            name="company-country"
                            label={t('contactInfo.country')}
                            items={[
                              { label: 'Switzerland', value: 'CH' },
                              { label: 'Austria', value: 'AT' },
                              { label: 'Germany', value: 'DE' },
                              { label: 'Denmark', value: 'DK' },
                              { label: 'United Kingdom', value: 'UK' }
                            ]}
                          />
                        </CcFieldGroup>
                        <CcFieldGroup>
                          <CcInput name="company-city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                            <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                          </CcInput>
                        </CcFieldGroup>
                      </CcFieldRow>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="company-street" label={t('contactInfo.streetAndNumber')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }

            <section>
              <div className="container">
                <div className="content">
                  <div className="btnHolder">
                    <CcButton text={t('common.continue')} link="financial-info" />
                  </div>
                </div>
              </div>
            </section>
          </form>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Occupation);