import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcRadio from '../components/form/radio';
import CcButton from '../components/form/button';

import style from './declarations.module.css';
import CcTooltip from '../components/common/tooltip';

class Declarations extends React.Component {

  constructor(props) {
    super(props);

    let owners = localStorage.getItem('ct-benefowners');
    this.maxKey = -1;

    this.state = {
      soleOwner: !owners,
      customOwners: [],
      politicalExposed: false
    };

    if (owners) {
      owners = JSON.parse(owners);

      for (const owner of owners) {
        this.state.customOwners.push({
          key: ++this.maxKey,
          firstname: owner.firstname,
          lastname: owner.lastname,
          ref: React.createRef()
        });
      }
    }
    else {
      this.state.customOwners = [{
        key: ++this.maxKey,
        ref: React.createRef()
      }];
    }

    this.addOwner = this.addOwner.bind(this);
    this.removeOwner = this.removeOwner.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  addOwner(ev) {
    let owners = this.state.customOwners;

    ev.preventDefault();

    owners.push({
      key: ++this.maxKey,
      ref: React.createRef()
    });

    this.setState({ customOwners: owners });
  }

  removeOwner(ix) {
    let owners = this.state.customOwners;

    owners.splice(ix, 1);
    this.setState({ customOwners: owners });
  }

  handleSubmit(ev) {
    const owners = [];

    if (!this.state.soleOwner) {
      for (let i = 0; i < this.state.customOwners.length; i++) {
        const firstname = this.state.customOwners[i].ref.current.querySelector('input[name=firstname-' + (i + 1) +']').value;
        const lastname = this.state.customOwners[i].ref.current.querySelector('input[name=lastname-' + (i + 1) + ']').value;

        if (firstname && lastname) {
          owners.push({
            firstname: firstname,
            lastname: lastname
          });
        }
      }

      if (owners.length) {
        localStorage.setItem('ct-benefowners', JSON.stringify(owners));
      }
    }

    if (!owners.length) {
      localStorage.removeItem('ct-benefowners');
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="personal-info" showPlatform={this.props.showPlatform} step="5" />
        <main className="main">
          <form autoComplete="off">

            <section>
              <div className="container">
                <h1>{t('declarations.pageTitle')}</h1>
                <h2>{t('declarations.pageSubtitle')}</h2>
                <div className="content">
                  <div>

                    <h3>{t('declarations.declarationOwner')}</h3>
                    <div>
                      {t('declarations.assets')}
                      <CcTooltip>{t('declarations.assetsDesc')}</CcTooltip>
                    </div>

                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="ownership-type"
                            value={this.state.soleOwner ? 0 : 1}
                            options={[
                              t('declarations.soleOwner'),
                              t('declarations.otherOwners')
                            ]}
                            cssClass="vertical"
                            customCssClass={style.ownership + (this.state.soleOwner ? '' : ' ' + style.multiOwners)}
                            onChange={ev => this.setState({ soleOwner: !+ev.target.value })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                    {
                      !this.state.soleOwner &&
                      <div className={style.owners}>
                        {
                          this.state.customOwners.map((owner, ix) =>
                            <div key={owner.key} ref={owner.ref}>
                              <h3 className={style.ownerTitle}>
                                {t('declarations.beneficialOwner')} {ix + 1}
                                {
                                  this.state.customOwners.length > 1 &&
                                  <span onClick={() => this.removeOwner(ix)}>
                                    <i className="cticon-denied" />
                                    {t('common.remove')}
                                  </span>
                                }
                              </h3>
                              <CcFieldSet>
                                <CcFieldRow>
                                  <CcFieldGroup>
                                    <CcInput name={'firstname-' + (ix + 1)} label={t('contactInfo.firstName')} value={owner.firstname} />
                                  </CcFieldGroup>
                                  <CcFieldGroup>
                                    <CcInput name={'lastname-' + (ix + 1)} label={t('contactInfo.lastName')} value={owner.lastname} />
                                  </CcFieldGroup>
                                </CcFieldRow>
                                <CcFieldRow>
                                  <CcFieldGroup>
                                    <CcInput name={'birth-date-' + (ix + 1)} label={t('personalInfo.birthDate')} type="date" />
                                  </CcFieldGroup>
                                  <CcFieldGroup>
                                    <CcSelectBox
                                      name={'birth-country-' + (ix + 1)}
                                      label={t('personalInfo.nationality')}
                                      value="CH"
                                      items={[
                                        { label: 'Austria', value: 'AT' },
                                        { label: 'Germany', value: 'DE' },
                                        { label: 'Switzerland', value: 'CH' },
                                        { label: 'Denmark', value: 'DK' },
                                        { label: 'United Kingdom', value: 'UK' }
                                      ]}
                                    />
                                  </CcFieldGroup>
                                </CcFieldRow>
                                <CcFieldRow>
                                  <CcFieldGroup>
                                    <CcSelectBox
                                      name={'residence-country-' + (ix + 1)}
                                      label={t('personalInfo.residenceCountry')}
                                      items={[
                                        { label: 'Austria', value: 'AT' },
                                        { label: 'Germany', value: 'DE' },
                                        { label: 'Switzerland', value: 'CH' },
                                        { label: 'Denmark', value: 'DK' },
                                        { label: 'United Kingdom', value: 'UK' }
                                      ]}
                                    />
                                  </CcFieldGroup>
                                  <CcFieldGroup>
                                    <CcInput name={'residence-city-' + (ix + 1)} label={t('personalInfo.residenceCity')} cssClass="prefixedWithLabel" customCssClass={style.residenceCity}>
                                      <CcInput name={'residence-postcode-' + (ix + 1)} label={t('contactInfo.nap')} cssClass="prefixWithLabel" customCssClass={style.nap} />
                                    </CcInput>
                                  </CcFieldGroup>
                                </CcFieldRow>
                                <CcFieldRow>
                                  <CcFieldGroup>
                                    <CcInput name={'residence-street-' + (ix + 1)} label={t('personalInfo.domicileAddress')} />
                                  </CcFieldGroup>
                                </CcFieldRow>
                              </CcFieldSet>
                            </div>
                          )
                        }
                        {
                          this.state.customOwners.length < 2 &&
                          <div className={style.addOwner}>
                            <a href="/" onClick={this.addOwner}>
                              {t('declarations.addOwner')}
                              <i className="cticon-add-user-male" />
                            </a>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </section>

            <section className="darker">
              <div className="container">
                <div className="content">

                  <h3>{t('declarations.politicalExposure')}</h3>
                  <div>{t('declarations.politicalExposed')}</div>
                  <div className={style.desc}>
                    {t('declarations.politicalExposedDesc')}
                    <CcTooltip>{t('declarations.politicalExposedDetails')}</CcTooltip>
                  </div>

                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcRadio
                          name="political"
                          options={[
                            t('common.yes'),
                            t('common.no')
                          ]}
                          onChange={ev => this.setState({ politicalExposed: !+ev.target.value })}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>
                    {
                      this.state.politicalExposed &&
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="political-position" label={t('declarations.politicalPosition')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    }
                  </CcFieldSet>

                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="content">
                  <div className="btnHolder">
                    <CcButton text={t('common.continue')} link="declaration-us" onClick={this.handleSubmit} />
                  </div>
                </div>
              </div>
            </section>

          </form>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Declarations);