import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcButton from '../components/form/button';

import style from './financial-info.module.css';

class FinancialInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      accountCurrency: 'chf',
      netAssetsIndex: 0
    };

    this.currencyRef = React.createRef();
    this.netAssetsRef = React.createRef();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="occupation" showPlatform={this.props.showPlatform} step="8" />
        <main className="main">
          <div className="container">
            <h1>{t('financialInfo.pageTitle')} 1/2</h1>
            <h2>{t('financialInfo.pageSubtitle')}</h2>
            <div className="content">
              <form autoComplete="off">

                <CcFieldSet>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelectBox
                        name="account-currency"
                        label={t('financialInfo.accountCurrency')}
                        items={[
                          { value: 'chf', label: 'CHF – Swiss Franc' },
                          { value: 'eur', label: 'EUR – Euro' },
                          { value: 'usd', label: 'USD – US Dollar' }
                        ]}
                        ref={this.currencyRef}
                        onChange={() => this.setState({ accountCurrency: (this.currencyRef.current?.state.selectedItem?.value || 'chf') })}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup cssClass="void" />
                  </CcFieldRow>
                  <h3>{t('financialInfo.fundsEstimation')}</h3>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelectBox
                        name="annual-income"
                        label={t('financialInfo.annualIncome')}
                        previewIndex="1"
                        items={[
                          { label: 'None', value: 0 },
                          { label: ['CHF', '< 100’000'], value: 1 },
                          { label: ['CHF', '100’000 - 300’000'], value: 2 },
                          { label: ['CHF', '300’000 - 1’000’000'], value: 3 },
                          { label: ['CHF', '> 1’000’000'], value: 4 }
                        ]}
                        currency={this.state.accountCurrency}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelectBox
                        name="net-assets"
                        label={t('financialInfo.netAssets')}
                        previewIndex="1"
                        items={[
                          { label: 'None', value: 0 },
                          { label: ['CHF', '< 500’000'], value: 1 },
                          { label: ['CHF', '500’000 - 2’000’000'], value: 2 },
                          { label: ['CHF', '2’000’000 - 5’000’000'], value: 3 },
                          { label: ['CHF', '> 5’000’000'], value: 4 }
                        ]}
                        currency={this.state.accountCurrency}
                        ref={this.netAssetsRef}
                        onChange={() => this.setState({ netAssetsIndex: (this.netAssetsRef.current?.state.selectedItem?.value || 0) })}
                      />
                    </CcFieldGroup>
                    {
                      this.state.netAssetsIndex === 4 &&
                      <CcFieldGroup>
                        <CcInput
                          name="net-approx"
                          label={t('financialInfo.netApproximate')}
                          currency={this.state.accountCurrency}
                        />
                      </CcFieldGroup>
                    }
                  </CcFieldRow>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelectBox
                        name="transferred-funds"
                        label={t('financialInfo.transferredFunds')}
                        previewIndex="1"
                        items={[
                          { label: ['CHF', '< 25’000'], value: 0 },
                          { label: ['CHF', '25’000 - 50’000'], value: 1 },
                          { label: ['CHF', '50’000 - 100’000'], value: 2 },
                          { label: ['CHF', '100’000 - 500’000'], value: 3 },
                          { label: ['CHF', '500’000 - 1’000’000'], value: 4 },
                          { label: ['CHF', '> 1’000’000'], value: 5 }
                        ]}
                        currency={this.state.accountCurrency}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>
                </CcFieldSet>

                <div className={style.convertor}>
                  <a href="/">
                    <i className="cticon-dollar-euro-exchange" />
                    {t('financialInfo.currencyConvertor')}
                  </a>
                </div>

                <div className="btnHolder">
                  <CcButton text={t('common.continue')} link="funds-origin" />
                </div>

              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(FinancialInfo);