import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcButton from '../components/form/button';

import style from './video-auth.module.css';

class VideoAuth extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="confirm" />
        <main className="main">

          <section>
            <div className={style.container}>
              <h1>{t('videoAuth.pageTitle')}</h1>
              <h2>{t('videoAuth.pageSubtitle')}</h2>
              <div className="content">
                <div className={style.btnHolder}>
                  <CcButton text={t('videoAuth.start')} disabled />
                </div>
                <h4>
                  <i className="cticon-checked-identification-documents"></i>
                  {t('videoAuth.serviceHours')}
                </h4>
                <p><Trans i18nKey="videoAuth.notification" components={{ B: <strong /> }} /></p>
              </div>
            </div>
          </section>

          <section className={style.darker}>
            <div className={style.container}>
              <h1>{t('videoAuth.classicWay')}</h1>
              <h2>{t('videoAuth.printContract')}</h2>
              <div className={style.forward}>
                <a href="contract">{t('contract.forward')}</a>
              </div>
            </div>
          </section>

        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(VideoAuth);