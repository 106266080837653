import React from 'react';
import style from './select.module.css';

class CcSelect extends React.Component {

  render() {
    let cssClass = style.field;
    
    if (this.props.invalid) {
      cssClass = style.invalid;
    }
    else if (this.props.valid) {
      cssClass = style.valid;
    }

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }
    
    const Options = () => {
      return (
        <div className={cssClass}>
          <select name={this.props.name} id={this.props.id} defaultValue={this.props.value}>
          {
            this.props.options.map((item, i) => (
              <option key={i} value={Array.isArray(item) ? item[0] : null}>{Array.isArray(item) ? item[1] : item}</option>
            ))
          }
          </select>
          {
            this.props.label &&
            <label className={style.label}>{this.props.label}</label>
          }
          {
            this.props.errorMsg &&
            <span className={style.error}>{this.props.errorMsg}</span>
          }
        </div>
      )
    };
    
    return (
      <Options />
    );
  }
}

export default CcSelect;
