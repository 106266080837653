import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcRadio from '../components/form/radio';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';

import style from './personal-info.module.css';

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      residenceCountry: null,
      diffAddress: false
    };

    /*
    this.supportedCountries = [
      'AT', 'AD', 'AG', 'AR', 'AX', 'BH', 'BB', 'BE', 'BR', 'VG', 'BG', 'KY', 'CO', 'CY', 'CZ', 'DK', 'EE', 'FI', 'DE', 'UA', 'GB', 'GI', 'GR', 'GG', 'HK', 'IS', 'IE', 'IM',
      'IL', 'JE', 'LV', 'LI', 'LT', 'LU', 'MT', 'MU', 'MX', 'NL', 'NZ', 'NO', 'PA', 'PT', 'QA', 'RO', 'RU', 'SA', 'SK', 'SI', 'ES', 'KN', 'SE', 'CH', 'TR', 'AE', 'UY'
    ];
    */
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="contact-info" showPlatform={this.props.showPlatform} step="3" />
        <main className="main">
          <form autoComplete="off">

            <section>
              <div className="container">
                <h1>{t('personalInfo.pageTitle')}</h1>
                <h2>{t('personalInfo.pageSubtitle')}</h2>
                <div className="content">
                  <CcFieldSet>

                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcInput name="birth-date" label={t('personalInfo.birthDate')} type="date" />
                      </CcFieldGroup>
                      <CcFieldGroup>
                        <CcSelectBox
                          name="birth-country"
                          label={t('personalInfo.birthCountry')}
                          items={[
                            { label: 'Switzerland', value: 'CH' },
                            { label: 'Austria', value: 'AT' },
                            { label: 'Germany', value: 'DE' },
                            { label: 'Denmark', value: 'DK' },
                            { label: 'United Kingdom', value: 'UK' }
                          ]}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>

                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcSelectBox
                          name="nationality"
                          label={t('personalInfo.nationality')}
                          items={[
                            { label: 'Switzerland', value: 'CH' },
                            { label: 'Austria', value: 'AT' },
                            { label: 'Germany', value: 'DE' },
                            { label: 'Denmark', value: 'DK' },
                            { label: 'United Kingdom', value: 'UK' }
                          ]}
                        />
                      </CcFieldGroup>
                      <CcFieldGroup>
                        <CcSelectBox
                          name="marital-status"
                          label={t('personalInfo.maritalStatus')}
                          items={[
                            'Single',
                            'Married',
                            'In a registered partnership',
                            'Separated',
                            'Divorced',
                            'Widowed'
                          ]}
                        />
                      </CcFieldGroup>
                      </CcFieldRow>

                  </CcFieldSet>
                </div>
              </div>
            </section>

            <section className="darker">
              <div className="container">
                <div className="content">
                  <CcFieldSet>
                    
                    <h3>{t('personalInfo.residenceAddress')}</h3>

                    <CcFieldRow>
                      <CcFieldGroup customCssClass={style.resCountry}>
                        <CcSelectBox
                          name="country"
                          label={t('contactInfo.country')}
                          items={[
                            { label: 'Switzerland', value: 'CH' },
                            { label: 'Austria', value: 'AT' },
                            { label: 'Germany', value: 'DE' },
                            { label: 'Denmark', value: 'DK' },
                            { label: 'United Kingdom', value: 'UK' }
                          ]}
                          onChange={(ev, component) => this.setState({ residenceCountry: component.state.selectedItem })}
                        />
                      </CcFieldGroup>
                      {
                        (!this.state.residenceCountry || this.state.residenceCountry.value === 'CH') &&
                        <CcFieldGroup>
                          <CcInput name="city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                            <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                          </CcInput>
                        </CcFieldGroup>
                      }
                    </CcFieldRow>
                    {
                      (!this.state.residenceCountry || this.state.residenceCountry.value === 'CH') &&
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcInput name="street" label={t('contactInfo.streetAndNumber')} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    }
                  </CcFieldSet>
                </div>
              </div>
            </section>
            {
              (!this.state.residenceCountry || this.state.residenceCountry.value === 'CH') &&
              <>
                <section>
                  <div className="container">
                    <div className="content">
                      <CcFieldSet>

                        <h3>{t('personalInfo.chResidencePermit')}</h3>

                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcRadio
                              name="work-permit"
                              options={['C', 'B']}
                            />
                          </CcFieldGroup>
                        </CcFieldRow>

                      </CcFieldSet>
                    </div>
                  </div>
                </section>

                <section className="darker">
                  <div className="container">
                    <div className="content">
                      <CcFieldSet>

                        <CcFieldRow>
                          <CcFieldGroup>
                            <CcCheckbox name="diff-address" customCssClass={style.diffAddress} onChange={ev => this.setState({ diffAddress: ev.target.checked })}>
                              {t('personalInfo.diffAddress')}
                            </CcCheckbox>
                          </CcFieldGroup>
                        </CcFieldRow>
                      </CcFieldSet>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="container">
                    <div className="content">
                      <CcFieldSet>
                        <div className={style.btnHolder}>
                          <CcButton text={t('common.continue')} link={this.state.diffAddress ? 'correspondence' : 'declarations'} />
                        </div>
                      </CcFieldSet>
                    </div>
                  </div>
                </section>
              </>
            }

            {
              this.state.residenceCountry && this.state.residenceCountry.value !== 'CH' &&
              <section>
                <div className="container">
                  <div className="content">
                    <div className={style.resCountryError}>
                      <h3>
                        <i className="cticon-info"></i>
                        <Trans i18nKey="personalInfo.countryNotSupported" components={{
                          Country: this.state.residenceCountry.label
                        }} />
                      </h3>
                      <div className={style.link}>
                        <a href="/">{t('common.cancelOnboarding')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            }

          </form>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(PersonalInfo);