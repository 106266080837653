import React from 'react';
import style from './field-grid.module.css';

class CcFieldSet extends React.Component {
  render() {
    return (
      <fieldset>
        {this.props.children}
      </fieldset>
    );
  }
}

class CcFieldRow extends React.Component {
  render() {
    let cssClass = style.fieldRow;

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        {this.props.children}
      </div>
    );
  }
}

class CcFieldGroup extends React.Component {
  render() {
    let cssClass = style.fieldGroup;

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        {this.props.children}
      </div>
    );
  }
}

export { CcFieldSet, CcFieldRow, CcFieldGroup };