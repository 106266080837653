import React from 'react';
import style from './checklist.module.css';

class CcChecklist extends React.Component {
  
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.initState();
    this.handleChange = this.handleChange.bind(this);
  }

  initState() {
    this.state = {};
    this.props.items.forEach((item, i) => {
      const { value, checked } = (
        typeof item === 'string' ? { value: i, checked: false } : {
          value: (item.value === undefined ? i : item.value),
          checked: (item.checked === undefined ? false : item.checked)
        }
      );
      this.state[this.props.name + '-' + value] = checked;
    });
  }
  
  handleChange(ev) {
    const fieldName = ev.target.name;

    this.setState(
      { [fieldName]: ev.target.checked },
      () => {
        if (this.props.onChange) {
          this.props.onChange(ev);
        }

        // Wait until the next JS tick to allow any onChange re-render tasks to be executed before focusing current item
        this.containerRef.current.querySelector('input[name=' + fieldName + ']').parentNode.focus();
      }
    );
  }
  
  render() {
    const Items = () => {
      let cssClass = style.field;

      if (this.props.cssClass) {
        cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
      }

      if (this.props.customCssClass) {
        cssClass += (' ' + this.props.customCssClass);
      }

      let inputs = this.props.items.map((item, i) => {
        const { label, value } = (
          typeof item === 'string' ? { label: item, value: i } : {
            label: item.label,
            value: (item.value === undefined ? i : item.value)
          }
        );
        const inputName = this.props.name + '-' + value;
        
        return (
          <label key={i} className={this.state[inputName] ? style.selected : ''}>
            {label}
            <input type="checkbox"
              name={inputName}
              checked={this.state[inputName]}
              onChange={this.handleChange}
              value={value} />
            <span className={style.icon}></span>
          </label>
        );
      });
      
      return (
        <div className={cssClass} ref={this.containerRef}>
          {inputs}
        </div>
      )
    };
    
    return (
      <Items />
    );
  }
}

export default CcChecklist;
