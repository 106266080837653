import React from 'react';
import style from './list.module.css';

class CcList extends React.Component {
  render() {
    let cssClass = style.list;

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        <ul>
          {
            this.props.items.map((item, i) => (
              <li key={i}>{item}</li>
            ))
          }
        </ul>
      </div>
    );
  }
}

export default CcList;