
export const TranslatDE = {
  common: {
    haveQuestions: 'Haben Sie Fragen?',
    back: 'Zurück',
    continue: 'Weiter',
    disclaimer: 'Disclaimer',
    impressum: 'Impressum',
    copyrightAddr: 'Tödistrasse 27, CH-8002, Zürich - Schweiz'
  },
  contactInfo: {
    mr: 'Herr',
    mrs: 'Frau',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    phoneNr: 'Telefonnummer',
    emailRequired: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
    phoneRequired: 'Bitte nur Zahlenformat, z.B: 7812234567'
  },
  
  // Demo screens
  demo: {
    referral: {
      title: 'Willkommen bei Cornèrtrader'
    },
    individual: {
      title: 'Kontaktinformation',
      subtitle: 'Wir freuen uns, dass Sie ein Konto eröffnen! Beginnen wir mit dem Wichtigsten: Wie heißt du und wie können wir dich erreichen?',
      safeData: 'Ihre Daten sind sicher bei uns.'
    }
  }
};
