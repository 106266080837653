import React from 'react';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import style from './footer.module.css';

class CcFooter extends React.Component {

  switchLang(lang, ev) {
    i18n.changeLanguage(lang);
    ev.preventDefault();
  }

  render() {
    const { t } = this.props;

    return (
      <footer>
        <div className={style.container}>
          <div className={style.menu}>
            <a href="/" onClick={this.switchLang.bind(this, 'en')}>EN</a>
            <span className={style.delimeter}></span>
            <a href="/" onClick={this.switchLang.bind(this, 'de')}>DE</a>
            <span className={style.delimeter}></span>
            <a href="/" target="_blank">{t('common.disclaimer')}</a>
            <span className={style.delimeter}></span>
            <a href="/" target="_blank">{t('common.impressum')}</a>
          </div>
          <div className={style.copyright}>
            © {new Date().getFullYear()} Cornèr Bank AG,<br />
            <span>{t('common.copyrightAddr')}</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(CcFooter);