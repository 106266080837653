import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcButton from '../components/form/button';

import style from './referral.module.css';

class Referral extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="/" />
        <main className={style.main}>
          <div className="container">
            <h1>{t('demo.referral.pageTitle')}</h1>
            <h2>{t('demo.referral.pageSubtitle')}</h2>
            <div className="content">
              <form autoComplete="off">
                <CcFieldSet>
                
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="reffirstname" label={t('demo.referral.firstName')} required />
                    </CcFieldGroup>
                  </CcFieldRow>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="reflastname" label={t('demo.referral.lastName')} required />
                    </CcFieldGroup>
                  </CcFieldRow>
                
                </CcFieldSet>
                <div className={style.btnHolder}>
                  <CcButton text={t('demo.referral.getStarted')} type="submit" />
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Referral);