import React from 'react';
import style from './button.module.css';

class CcButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }

    if (this.props.onClick) {
      this.props.onClick(ev);
    }
  }

  render() {
    let cssClass = style.field;

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    if (this.props.link) {
      return (
        <a
          className={cssClass}
          href={this.props.link}
          onClick={this.handleClick}
          disabled={this.props.disabled}>
          {
            this.props.icon &&
            <i className={style.icon + ' cticon-' + this.props.icon + (this.props.iconSpin ? ' spin' : '')}></i>
          }
          { this.props.text }
        </a>
      );
    }

    return (
      <button
        className={cssClass}
        type={this.props.type || 'button'}
        onClick={this.handleClick}
        disabled={this.props.disabled}>
        {
          this.props.icon &&
          <i className={style.icon + ' cticon-' + this.props.icon + (this.props.iconSpin ? ' spin' : '')}></i>
        }
        { this.props.text }
      </button>
    );
  }
}

export default CcButton;