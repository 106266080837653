import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcCheckbox from '../components/form/checkbox';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcRadio from '../components/form/radio';
import CcButton from '../components/form/button';

import style from './traderscard.module.css';
import visaImg from '../assets/img/ct-visa-sm.png';

class TradersCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cardCurrency: 'chf',
      travelInsurance: null
    };

    this.currencyRef = React.createRef();
    this.insuranceRefs = {
      individual: React.createRef(),
      family: React.createRef()
    };

    this.changeTravelInsurance = this.changeTravelInsurance.bind(this);
  }

  changeTravelInsurance(ev, type) {
    this.setState({
      travelInsurance: (ev.target.checked ? type : null)
    });

    if (ev.target.checked) {
      this.insuranceRefs[type === 'family' ? 'individual' : 'family'].current.setState({ checked: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="traderscard-intro" showPlatform={this.props.showPlatform} step="11" />
        <main className="main">
          <form autoComplete="off">

            <section>
              <div className={style.container}>
                <div className="content">
                  <div className={style.card}>
                    <img src={visaImg} alt="" />
                  </div>
                  <h1>{t('tradersCard.pageTitle')}</h1>
                  <h2>{t('tradersCard.pageSubtitle')}</h2>
                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcSelectBox
                          name="currency"
                          label={t('tradersCard.cardCurrency')}
                          items={[
                            { value: 'usd', label: 'USD – U.S. dollar' },
                            { value: 'eur', label: 'EUR – Euro' },
                            { value: 'chf', label: 'CHF – Swiss Franc' },
                            { value: 'czk', label: 'CZK – Czech crown' },
                            { value: 'dkk', label: 'DKK – Danish krone' },
                            { value: 'gbp', label: 'GBP – Pound sterling' },
                            { value: 'uah', label: 'UAH – Ukrainian hryvnia' }
                          ]}
                          ref={this.currencyRef}
                          onChange={() => this.setState({ cardCurrency: (this.currencyRef.current?.state.selectedItem?.value || 'chf') })}
                        />
                      </CcFieldGroup>
                      <CcFieldGroup cssClass="void" />
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>

            <section>
              <div className={style.container}>
                <div className="content">
                  <h3>{t('tradersCard.rentingOrOwning')}</h3>
                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcRadio
                          name="home"
                          options={[
                            t('tradersCard.renting'),
                            t('tradersCard.owning')
                          ]}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcInput name="home-cost" label={t('tradersCard.homeAnnualCost')} currency={this.state.cardCurrency} />
                      </CcFieldGroup>
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>

            <section className="darker">
              <div className={style.container}>
                <div className="content">
                  <h3>{t('tradersCard.travelInsurance') + ' (' + t('common.optional') + ')'}</h3>
                  <div className={style.desc}>{t('tradersCard.travelInsuranceDesc')}</div>
                  <CcFieldSet>
                    <CcFieldRow customCssClass={style.insurance}>
                      <CcFieldGroup>
                        <CcCheckbox
                          name="individual"
                          cssClass="circle"
                          customCssClass={style.insuranceItem}
                          ref={this.insuranceRefs.individual}
                          checked={this.state.travelInsurance === 'individual'}
                          onChange={ev => this.changeTravelInsurance(ev, 'individual')}
                        >
                          <h4 className={this.state.travelInsurance === 'individual' ? style.selected : null}>
                            <span>{t('tradersCard.individualInsurance')}</span>
                            <span>{t('tradersCard.individualInsurancePrice')}</span>
                          </h4>
                          <span className={style.serviceDesc}>
                            {t('tradersCard.individualInsuranceDesc')}
                          </span>
                        </CcCheckbox>
                      </CcFieldGroup>
                      <CcFieldGroup>
                        <CcCheckbox
                          name="family"
                          cssClass="circle"
                          customCssClass={style.insuranceItem}
                          ref={this.insuranceRefs.family}
                          checked={this.state.travelInsurance === 'family'}
                          onChange={ev => this.changeTravelInsurance(ev, 'family')}
                        >
                          <h4 className={this.state.travelInsurance === 'family' ? style.selected : null}>
                            <span>{t('tradersCard.familyInsurance')}</span>
                            <span>{t('tradersCard.familyInsurancePrice')}</span>
                          </h4>
                          <span className={style.serviceDesc}>
                            {t('tradersCard.familyInsuranceDesc')}
                          </span>
                        </CcCheckbox>
                      </CcFieldGroup>
                    </CcFieldRow>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <div className={style.notice}>{t('tradersCard.notice')}</div>
                      </CcFieldGroup>
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>

            <section>
              <div className={style.container}>
                <div className="content">
                  <h3>{t('tradersCard.iCornerFree')}</h3>
                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcCheckbox name="icorner">
                          <h4>{t('tradersCard.accessPortal')}</h4>
                          <span className={style.serviceDesc}>{t('tradersCard.portalDesc')}</span>
                        </CcCheckbox>
                      </CcFieldGroup>
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="content">
                  <div className={style.btnHolder}>
                    <CcButton text={t('tradersCard.request')} link="confirm" />
                    <div className={style.skip}>
                      <a href="confirm">{t('tradersCard.skip')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(TradersCard);