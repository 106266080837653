import React from 'react';
import style from './checkbox.module.css';

class CcCheckbox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: !!props.checked
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    this.setState(
      { checked: ev.target.checked },
      () => this.props.onChange && this.props.onChange(ev, this.props)
    );
  }

  render() {
    const checked = !!this.state.checked;
    let cssClass = style.field;
    
    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }
    
    return (
      <div className={cssClass}>
        <label>
          { this.props.label || this.props.children }
          <input
            key={Math.random()}
            type="checkbox"
            name={this.props.name}
            checked={checked}
            onChange={this.handleChange}
          />
          <span className={style.icon}></span>
        </label>
      </div>
    );
  }
}

export default CcCheckbox;