import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcList from '../components/common/list';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcRadio from '../components/form/radio';
import CcInput from '../components/form/input';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';

import style from './declaration-us.module.css';

class DeclarationUS extends React.Component {

  constructor(props) {
    super(props);

    this.benefOwners = localStorage.getItem('ct-benefowners');
    this.benefOwners = (this.benefOwners ? JSON.parse(this.benefOwners) : []);

    this.state = {
      usPerson: null
    };
  }
  
  render() {
    const { t } = this.props;
    
    return (
      <>
        <CcHeader prev="declarations" showPlatform={this.props.showPlatform} step="6" />
        <main className={style.main}>
          <form autoComplete="off">

            <section>
              <div className="container">
                <h1>{t('declarationUS.pageTitle')}</h1>
                <h2>{t('declarationUS.pageSubtitle')}</h2>
                <div className="content">
                  <div className={style.compliance}>{t('declarationUS.compliance')}</div>
                  <h3>{t('declarationUS.statusCert')}</h3>
                  <div className={style.statusCert}>{t('declarationUS.statusCertDesc')}</div>
                  <h3>
                    {t(!this.benefOwners.length ? 'declarationUS.usCitizen' : 'declarationUS.usCitizenBenefOwner')}
                    <span className={style.desc}> {t('declarationUS.usCitizenDesc')}</span>
                  </h3>
                  <CcFieldSet>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcRadio
                          name="us-citizen"
                          options={[
                            [t('common.yes'), 1],
                            [t('common.no'), 0]
                          ]}
                          onChange={ev => this.setState({ usPerson: +ev.target.value ? 1 : 0 })}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>

            {
              (this.state.usPerson === 0 || this.state.usPerson > 1) &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t(!this.benefOwners.length ? 'declarationUS.usResident' : 'declarationUS.usResidentBenefOwner')}</h3>
                    <div className={style.desc}>{t('declarationUS.usResidentDesc')}</div>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="us-resident"
                            options={[
                              [t('common.yes'), 1],
                              [t('common.no'), 0]
                            ]}
                            onChange={ev => this.setState({ usPerson: +ev.target.value ? 2 : 0 })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              (this.state.usPerson === 0 || this.state.usPerson > 2) &&
              <section>
                <div className="container">
                  <div className="content">
                    <h3>{t(!this.benefOwners.length ? 'declarationUS.usBorn' : 'declarationUS.usBornBenefOwner')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="us-born"
                            options={[
                              [t('common.yes'), 1],
                              [t('common.no'), 0]
                            ]}
                            onChange={ev => this.setState({ usPerson: +ev.target.value ? 3 : 0 })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              (this.state.usPerson === 0 || this.state.usPerson > 3) &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t(!this.benefOwners.length ? 'declarationUS.usTaxpayer' : 'declarationUS.usTaxpayerBenefOwner')}</h3>
                    <div className={style.desc}>{t('declarationUS.usTaxpayerDesc')}</div>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="us-taxpayer"
                            options={[
                              [t('common.yes'), 1],
                              [t('common.no'), 0]
                            ]}
                            onChange={ev => this.setState({ usPerson: +ev.target.value ? 4 : 0 })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              (this.state.usPerson === 0 || this.state.usPerson > 4) &&
              <section>
                <div className="container">
                  <div className="content">
                    <div className={style.fatca}>
                      {t('declarationUS.fatca')}
                      <CcList
                        items={[
                          t('declarationUS.fatcaData1'),
                          t('declarationUS.fatcaData2'),
                          t('declarationUS.fatcaData3'),
                          t('declarationUS.fatcaData4'),
                          t('declarationUS.fatcaData5')
                        ]}
                      />
                    </div>
                    <h3>{t('declarationUS.fatcaConfirm')}</h3>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcRadio
                            name="fatca-confirm"
                            options={[
                              [t('common.yes'), 1],
                              [t('common.no'), 0]
                            ]}
                            onChange={ev => this.setState({ usPerson: +ev.target.value ? 5 : 0 })}
                          />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              this.state.usPerson === 0 && !this.benefOwners.length &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t('declarations.beneficialOwner')}</h3>
                    <div className={style.desc}>{t('declarationUS.irsConfirm')}</div>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcCheckbox name="irs-confirm" label={t('declarationUS.irsSoleOwner')} customCssClass={style.irsSoleOwner} />
                        </CcFieldGroup>
                      </CcFieldRow>
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              this.state.usPerson === 0 && this.benefOwners.length > 0 &&
              <section className="darker">
                <div className="container">
                  <div className="content">
                    <h3>{t('declarations.beneficialOwner')}</h3>
                    <div className={style.desc}>{t('declarationUS.irsConfirmBenefOwner')}</div>
                    <CcFieldSet>
                      <CcFieldRow>
                        <CcFieldGroup>
                          <CcCheckbox name="irs-confirm" label={t('declarationUS.irsBenefOwner')} customCssClass={style.irsSoleOwner} />
                        </CcFieldGroup>
                      </CcFieldRow>
                      {
                        this.benefOwners.map((owner, ix) =>
                          <CcFieldRow key={ix}>
                            <CcFieldGroup>
                              <CcInput name={'firstname-benef-owner-' + (ix + 1)} label={t('contactInfo.firstName')} value={owner.firstname} />
                            </CcFieldGroup>
                            <CcFieldGroup>
                              <CcInput name={'lastname-benef-owner-' + (ix + 1)} label={t('contactInfo.lastName')} value={owner.lastname} />
                            </CcFieldGroup>
                          </CcFieldRow>
                        )
                      }
                    </CcFieldSet>
                  </div>
                </div>
              </section>
            }
            {
              this.state.usPerson === 0 &&
              <section>
                <div className="container">
                  <div className="content">
                    <div className={style.changeNonUS}>
                      <h3>
                        <i className="cticon-regular-document"></i>
                        {t('declarationUS.changeNonUS')}
                      </h3>
                      <div className={style.desc}>{t('declarationUS.changeNonUSDesc')}</div>
                    </div>
                    <div className={style.btnHolder}>
                      <CcButton text={t('common.continue')} link="occupation" />
                    </div>
                  </div>
                </div>
              </section>
            }
            {
              this.state.usPerson > 0 &&
              <section>
                <div className="container">
                  <div className="content">
                    <div className={style.sorry}>
                      <h3>
                        <i className="cticon-info"></i>
                        {t('declarationUS.sorry')}
                      </h3>
                      <div className={style.link}>
                        <a href="/">{t('common.backHome')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            }

          </form>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(DeclarationUS);