import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcSlider from '../components/common/slider';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';
import CcModal from '../components/common/modal';

import style from './platform.module.css';
import demoImg from '../assets/img/dummy.jpg';

class Platform extends React.Component {

  platforms = ['invest', 'flex', 'advance'];

  sliderSettings = {
    centerMode: true,
    centerPadding: '115px',
    initialSlide: 1,
    slidesToShow: 1,
    infinite: false,
    arrows: false,
    dots: true,
    appendDots: (dots) => {
      return <div><ul className={style.sliderDots}>{dots}</ul></div>;
    },
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          accessibility: false,
          draggable: false,
          swipe: false,
          touchMove: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '115px',
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '60px'
        }
      },
      {
        breakpoint: 560,
        settings: {
          centerPadding: '87px'
        }
      }
    ]
  };

  constructor(props) {
    super(props);

    const selPlatform = localStorage.getItem('ct-platform');

    this.state = {
      selPlatform: selPlatform,
      confirmed: false,
      modals: {}
    };

    this.sliderSettings.initialSlide = (selPlatform ? this.platforms.indexOf(selPlatform) : 1);
    this.handleSelection = this.handleSelection.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.initPlatformRefs();
  }

  initPlatformRefs() {
    this.platformRefs = {};

    for (let i = 0; i < this.platforms.length; i++) {
      this.platformRefs[this.platforms[i]] = React.createRef();
    }
  }

  handleSelection(ev, props) {
    this.setState({
      selPlatform: (ev.target.checked ? props.value : null)
    });

    if (ev.target.checked) {
      localStorage.setItem('ct-platform', props.value);

      // Deselect all other platforms
      for (let name in this.platformRefs) {
        if (this.platformRefs[name].current.props.value !== props.value) {
          this.platformRefs[name].current.setState({ checked: false });
        }
      }
    }
    else {
      localStorage.removeItem('ct-platform');
    }
  }

  showModal(ev) {
    ev.preventDefault();

    if (ev.target.dataset.modal) {
      this.state.modals[ev.target.dataset.modal] = { show: true };
      this.setState(this.state.modals);
    }
  }

  hideModal(ev) {
    const modalId = ev.target.closest('.modal-holder').id;

    ev.preventDefault();
    ev.stopPropagation();

    if (modalId) {
      let modals = this.state.modals;
      modals[modalId] = { show: false };
      this.setState({ modals });
    }
  }

  render() {
    const { t } = this.props;
    
    return (
      <>
        <CcHeader />
        <main className="main">
          <div className="container">
            <h1>{t('platform.pageTitle')}</h1>
            <h2>{t('platform.pageSubtitle')}</h2>
            <div className="content">
              <div className={style.slider}>
                <CcSlider settings={this.sliderSettings} customCssClass="allow-overflow">
                  {
                    this.platforms.map((platform, ix) =>
                      <div key={ix}>
                        <div className={style['sliderItem' + platform.toUpperCase()] + (this.state.selPlatform === platform ? ' ' + style.sliderSelItem : '')}>
                          <div className={style.sliderPanel}>
                            <div className={style.sliderImg}>
                              <img src={demoImg} alt="" />
                            </div>
                            <div className={style.sliderInfo}>
                              <h3>{t('platform.' + platform)}</h3>
                              <div>{t('platform.' + platform + 'Desc')}</div>
                              <a href="/">{t('platform.more')}</a>
                            </div>
                          </div>
                          <div>
                            <CcCheckbox
                              label={t(this.state.selPlatform === platform ? 'platform.selected' : 'platform.select')}
                              cssClass="circle"
                              customCssClass={style.sliderSelect}
                              value={platform}
                              ref={this.platformRefs[platform]}
                              onChange={this.handleSelection}
                              checked={this.state.selPlatform === platform}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                </CcSlider>
              </div>
              <form autoComplete="off">
                <CcFieldSet>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="confirm" customCssClass={style.confirm} onChange={ev => this.setState({ confirmed: ev.target.checked })}>
                        <Trans i18nKey="platform.confirm" components={{
                          Requirements: <a href="/" target="_blank" />
                        }} />
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>
                </CcFieldSet>
                <div className={style.btnHolder}>
                  <CcButton
                    text={t('common.continue')}
                    link='/'
                    disabled={!this.state.selPlatform || !this.state.confirmed}
                  />
                </div>
                <div style={{ margin: '-40px 0 110px', textAlign: 'center' }}>
                  <CcModal
                    id="leaveModal"
                    show={this.state.modals['leaveModal'] && this.state.modals['leaveModal'].show}
                    handleClose={this.hideModal}
                    backdropClose={true}
                  >
                    <div className={style.leaveModalInfo}>
                      <h2>{t('platform.sureLeave')}</h2>
                      {t('platform.leaveWarning')}
                    </div>
                    <div className={style.leaveModalBtnHolder}>
                      <CcButton text={t('platform.continueOnboarding')} onClick={this.hideModal} />
                      <CcButton text={t('platform.tryFreeDemo')} cssClass="secondary" />
                    </div>
                    <div className={style.leaveModalYesCancel}>
                      <a href="/">{t('platform.yesCancel')}</a>
                    </div>
                  </CcModal>
                  <a href="/" onClick={this.showModal} data-modal="leaveModal">Leave modal demo</a>
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Platform);