import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcSlider from '../components/common/slider';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';
import CcModal from '../components/common/modal';

import style from './platform.module.css';
import flexImg from '../assets/img/platforms/platform-flexi.jpg';
import investImg from '../assets/img/platforms/platform-invest.jpg';
import advanceImg from '../assets/img/platforms/platform-advance.jpg';

class Platform extends React.Component {

  platforms = [
    {
      name: 'invest',
      img: investImg,
      products: ['EQ', 'Bo', 'MF', 'ETF', 'ETP', 'ETN', 'ETC']
    },
    {
      name: 'flex',
      img: flexImg,
      products: ['EQ', 'EQo', 'Bo', 'MF', 'ETF', 'ETP', 'ETN', 'ETC', 'FX', 'FXo', 'CFX', 'CFD', 'CFDo', 'FU', 'Co']
    },
    {
      name: 'advance',
      img: advanceImg,
      products: ['EQ', 'EQo', 'Bo', 'MF', 'ETF', 'ETP', 'ETN', 'ETC', 'FX', 'FXo', 'CFX', 'CFD', 'CFDo', 'FU', 'Co']
    }
  ];

  sliderSettings = {
    centerMode: true,
    centerPadding: '104px',
    initialSlide: 1,
    slidesToShow: 1,
    infinite: false,
    arrows: false,
    dots: true,
    appendDots: (dots) => {
      return <div><ul className={style.sliderDots}>{dots}</ul></div>;
    },
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          accessibility: false,
          draggable: false,
          swipe: false,
          touchMove: false,
          dots: false,
          initialSlide: 0,
          slidesToShow: 3,
          centerPadding: 0
        }
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '104px',
          dots: true,
          initialSlide: 1,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '60px'
        }
      },
      {
        breakpoint: 560,
        settings: {
          centerPadding: '87px'
        }
      }
    ]
  };

  constructor(props) {
    super(props);

    const selPlatform = localStorage.getItem('ct-platform');

    this.state = {
      selPlatform: selPlatform,
      confirmed: false,
      modals: {}
    };

    this.sliderSettings.initialSlide = (selPlatform ? this.platforms.findIndex(platform => platform.name === selPlatform) : 1);
    this.handleSelection = this.handleSelection.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  handleSelection(ev, platform) {
    ev.preventDefault();

    this.setState(
      { selPlatform: (this.state.selPlatform === platform ? null : platform) },
      () => {
        if (this.state.selPlatform) {
          localStorage.setItem('ct-platform', this.state.selPlatform);
        }
        else {
          localStorage.removeItem('ct-platform');
        }
      }
    );
  }

  showModal(ev) {
    ev.preventDefault();

    if (ev.target.dataset.modal) {
      this.state.modals[ev.target.dataset.modal] = { show: true };
      this.setState(this.state.modals);
    }
  }

  hideModal(ev) {
    const modalId = ev.target.closest('.modal-holder').id;

    ev.preventDefault();
    ev.stopPropagation();

    if (modalId) {
      this.state.modals[modalId] = { show: false };
      this.setState(this.state.modals);
    }
  }

  render() {
    const { t } = this.props;
    const Slider = () => {
      return (
        <div className={style.slider}>
          <CcSlider settings={this.sliderSettings} customCssClass="allow-overflow">
            {
              this.platforms.map((platform, ix) =>
                <div key={ix}>
                  <div className={style.sliderItem + (this.state.selPlatform === platform.name ? ' ' + style.sliderSelItem : '')}>
                    <div className={style.slidePanel}>
                      <div className={style.slideHeader}>
                        <div className={style.slideTitle}>
                          <h4>{t('platform.' + platform.name + 'SubTitle')}</h4>
                          <h3>{t('platform.' + platform.name)}</h3>
                          <div>{t('platform.' + platform.name + 'Desc')}</div>
                        </div>
                        <div className={style.slideImg}>
                          <img src={platform.img} alt="" />
                        </div>
                      </div>
                      <div className={style.slideData}>
                        <div className={style.slideInfo}>
                          <ul>
                            <li>{t('platform.' + platform.name + 'Info1')}</li>
                            <li>{t('platform.' + platform.name + 'Info2')}</li>
                            {
                              platform.name === 'advance' && <li>{t('platform.advanceInfo3')}</li>
                            }
                          </ul>
                          <div className={style.slideIcons}>
                            {
                              platform.products.map((product, pix) => <div className={style['icon' + product]} title={t('platform.products.' + product)} key={pix}>{product}</div>)
                            }
                          </div>
                        </div>
                        <div className={style.slideSelect}>
                          <a href="/" onClick={ev => this.handleSelection(ev, platform.name)}>
                            <i className="cticon-ok-2"></i>
                            <span>
                              {this.state.selPlatform === platform.name ? t('platform.selected') : t('platform.select') + ' ' + t('platform.' + platform.name)}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </CcSlider>
        </div>
      );
    };
    
    return (
      this.props.modal ?
      <div className={style.modalView}>
        <h1>{t('platform.pageTitle')}</h1>
        <h2>{t('platform.pageSubtitle')}</h2>
        <div className={style.content}>
          <Slider />
        </div>
      </div>
      :
      <>
        <CcHeader />
        <main className="main">
          <div className="container">
            <h1>{t('platform.pageTitle')}</h1>
            <h2>{t('platform.pageSubtitle')}</h2>
            <div className="content">
              {
                /*
                 * TODO: Check how to solve selection flickering and missing animation on buttons when using <Slider /> instead of the slider DIV below.
                */
              }
              <div className={style.slider}>
                <CcSlider settings={this.sliderSettings} customCssClass="allow-overflow">
                  {
                    this.platforms.map((platform, ix) =>
                      <div key={ix}>
                        <div className={style.sliderItem + (this.state.selPlatform === platform.name ? ' ' + style.sliderSelItem : '')}>
                          <div className={style.slidePanel}>
                            <div className={style.slideHeader}>
                              <div className={style.slideTitle}>
                                <h4>{t('platform.' + platform.name + 'SubTitle')}</h4>
                                <h3>{t('platform.' + platform.name)}</h3>
                                <div>{t('platform.' + platform.name + 'Desc')}</div>
                              </div>
                              <div className={style.slideImg}>
                                <img src={platform.img} alt="" />
                              </div>
                            </div>
                            <div className={style.slideData}>
                              <div className={style.slideInfo}>
                                <ul>
                                  <li>{t('platform.' + platform.name + 'Info1')}</li>
                                  <li>{t('platform.' + platform.name + 'Info2')}</li>
                                  {
                                    platform.name === 'advance' && <li>{t('platform.advanceInfo3')}</li>
                                  }
                                </ul>
                                <div className={style.slideIcons}>
                                  {
                                    platform.products.map((product, pix) => <div className={style['icon' + product]} title={t('platform.products.' + product)} key={pix}>{product}</div>)
                                  }
                                </div>
                              </div>
                              <div className={style.slideSelect}>
                                <a href="/" onClick={ev => this.handleSelection(ev, platform.name)}>
                                  <i className="cticon-ok-2"></i>
                                  <span>
                                    {this.state.selPlatform === platform.name ? t('platform.selected') : t('platform.select') + ' ' + t('platform.' + platform.name)}
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </CcSlider>
              </div>
              <form autoComplete="off">
                <CcFieldSet>
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="confirm" customCssClass={style.confirm} onChange={ev => this.setState({ confirmed: ev.target.checked })}>
                        <Trans i18nKey="platform.confirm" components={{
                          Requirements: <a href="/" target="_blank" />
                        }} />
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>
                </CcFieldSet>
                <div className={style.btnHolder}>
                  <CcButton
                    text={t('common.continue')}
                    link='contact-info'
                    disabled={!this.state.selPlatform || !this.state.confirmed}
                  />
                </div>
                <div style={{ margin: '-40px 0 110px', textAlign: 'center' }}>
                  <CcModal
                    id="leaveModal"
                    show={this.state.modals['leaveModal'] && this.state.modals['leaveModal'].show}
                    handleClose={this.hideModal}
                    backdropClose={true}
                  >
                    <div className={style.leaveModalInfo}>
                      <h2>{t('platform.sureLeave')}</h2>
                      {t('platform.leaveWarning')}
                    </div>
                    <div className={style.leaveModalBtnHolder}>
                      <CcButton text={t('platform.continueOnboarding')} onClick={this.hideModal} />
                      <CcButton text={t('platform.tryFreeDemo')} cssClass="secondary" />
                    </div>
                    <div className={style.leaveModalYesCancel}>
                      <a href="/">{t('platform.yesCancel')}</a>
                    </div>
                  </CcModal>
                  <a href="/" onClick={this.showModal} data-modal="leaveModal">Leave modal demo</a>
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Platform);