import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcRadio from '../components/form/radio';
import CcSelectBox from '../components/form/selectbox';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';

import style from './contact-info.module.css';

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      phoneType: 'mobile',
      termsAccepted: false
    };

    this.switchPhoneType = this.switchPhoneType.bind(this);
  }

  switchPhoneType(ev) {
    this.setState({ phoneType: ev.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="platform" showPlatform={this.props.showPlatform} step="2" />
        <main className="main">
          <div className="container">
            <h1>{t('contactInfo.pageTitle')}</h1>
            <h2>{t('contactInfo.pageSubtitle')}</h2>
            <div className="content">
              <form autoComplete="off">
                <CcFieldSet>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcRadio
                        name="gender"
                        value="M"
                        options={[
                          [t('contactInfo.mr'), 'M'],
                          [t('contactInfo.mrs'), 'F']
                        ]}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="firstname" label={t('contactInfo.firstName')} required />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <CcInput name="lastname" label={t('contactInfo.lastName')} required />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="email" label={t('contactInfo.email')} type="email" required errorMsg={t('contactInfo.emailRequired')} />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <h3>{t('contactInfo.phoneNr')}</h3>

                  <CcFieldRow>
                    <CcFieldGroup cssClass="radioHolder">
                      <CcRadio
                        name="phone-type"
                        value={this.state.phoneType}
                        onChange={this.switchPhoneType}
                        options={[
                          [t('contactInfo.mobile'), 'mobile'],
                          [t('contactInfo.landline'), 'landline']
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <CcInput
                        type="tel"
                        name="phone"
                        pattern="[0-9]{2}[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}"
                        label={t('contactInfo.' + this.state.phoneType)}
                        type="tel"
                        placeholder="00 000 00 00"
                        cssClass="prefixed"
                        required
                        errorMsg={t('contactInfo.phoneRequired')}
                      >
                        <CcSelectBox
                          cssClass="prefix"
                          value="41"
                          previewIndex="1"
                          items={[
                            { label: ['Switzerland', '+41'], value: '41', icon: 'flags/switzerland-24.png' },
                            { label: ['Austria', '+43'], value: '43', icon: 'flags/austria-24.png' },
                            { label: ['United Kingdom', '+44'], value: '44', icon: 'flags/united-kingdom-24.png' },
                            { label: ['Denmark', '+45'], value: '45', icon: 'flags/denmark-24.png' },
                            { label: ['Germany', '+49'], value: '49', icon: 'flags/germany-24.png' }
                          ]}
                        />
                      </CcInput>
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="terms" customCssClass={style.terms} onChange={ev => this.setState({ termsAccepted: ev.target.checked })}>
                        <Trans i18nKey="contactInfo.gtcAgree" components={{
                          Terms: <a href="/" target="_blank" />,
                          Disclaimer: <a href="/" target="_blank" />
                        }} />
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>

                </CcFieldSet>
                <div className="btnHolder">
                  <CcButton text={t('common.continue')} link="personal-info" disabled={!this.state.termsAccepted} />
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(ContactInfo);