
/* Examples of CcHeader with and without Back button:
 *   <CcHeader prev="route_path_for_back_button" /> 
 *   <CcHeader />
 * Use the 'showPlatform' attribute to show the platform bar and the 'step' attribute to show the step progress bar with current step:
 *   <CcHeader prev="/" showPlatform={this.props.showPlatform}  step="3" />
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import style from './header.module.css';
import CcLogo from '../common/logo';
import CcBackBtn from '../common/back-btn';

class CcHeader extends React.Component {
  render() {
    const { t } = this.props;
    const platform = (this.props.showPlatform === undefined ? null : localStorage.getItem('ct-platform'));
    const totalSteps = 12;
    let cssClass = (platform ? style.withPlatform : '') + ' ' + (this.props.step ? style.withProgress : '');
    
    return (
      <header className={cssClass === ' ' ? null : cssClass}>
        <div>
          <div className={style.container}>
            {
              this.props.prev && <CcBackBtn to={this.props.prev} customCssClass={style.backBtn} />
            }
            <CcLogo customCssClass={this.props.prev ? style.logoRight : style.logo} />
            <div className={!this.props.prev && !this.props.step ? ' ' + style.contactRight : style.contact}>
              <span>{t('common.haveQuestions')}</span>
              <a href="tel:00411234567">+41 123 45 67</a>
            </div>
            {
              this.props.step &&
              <div className={style.progress}>
                <div style={{ width: (this.props.step * 100 / totalSteps) + '%' }} />
              </div>
            }
          </div>
          {
            platform &&
            <div className={style.platform}>
              <span className={style.platformName}>
                {t('platform.' + platform) + ' ' + t('platform.account')}
              </span>
              <a href="platform">
                {t('common.change')}
              </a>
            </div>
          }
        </div>
      </header>
    );
  }
}

export default withTranslation()(CcHeader);