import React from 'react';
import { withTranslation } from 'react-i18next';
import style from './panel.module.css';

class CcPanel extends React.Component {

  constructor(props) {
    super(props);

    this.expandable = (this.props.expandable || this.props.expanded);

    if (this.expandable) {
      this.state = { contentHeight: 0 };
      this.toggleContent = this.toggleContent.bind(this);
    }
  }

  componentDidMount() {
    if (this.props.expanded) {
      this.toggleContent();
    }
  }

  toggleContent(ev) {
    this.setState(
      { contentHeight: (this.state.contentHeight ? 0 : this.contentRef.scrollHeight) }
    );
  }

  render() {
    let cssClass = style.panel;

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    if (this.expandable) {
      cssClass += (' ' + style.expandable + (this.state.contentHeight ? ' ' + style.expanded : ''));
    }

    return (
      <div className={cssClass}>
        {
          this.expandable &&
          <>
            <div className={style.title} onClick={this.toggleContent}>
              <span>{this.props.title || <>&nbsp;</>}</span>
              <i className={style.icon + ' cticon-expand-arrow'}></i>
            </div>
            <div
              className={style.extension}
              style={{ maxHeight: `${this.state.contentHeight}px` }}
              ref={ref => this.contentRef = ref }
            >
              {this.props.children}
            </div>
          </>
        }
        {
          !this.expandable && this.props.title &&
          <>
            <div className={style.title}>
              <span>{this.props.title}</span>
              {
                this.props.icon &&
                <i className={style.icon + ' cticon-' + this.props.icon}></i>
              }
            </div>
            {
              this.props.children &&
              <div className={style.content}>
                {this.props.children}
              </div>
            }
          </>
        }
        {
          !this.expandable && !this.props.title &&
          <>
            {
              this.props.icon &&
              <i className={style.icon + ' cticon-' + this.props.icon}></i>
            }
            <div className={style.content}>
              {this.props.children || <>&nbsp;</>}
            </div>
          </>
        }
      </div>
    );
  }
}

export default withTranslation()(CcPanel);