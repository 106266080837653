import React from 'react';
import { withTranslation } from 'react-i18next';
import style from './back-btn.module.css';

class CcBackBtn extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <a href={this.props.to} className={style.ccBackBtn + (this.props.customCssClass ? ' ' + this.props.customCssClass : '')}>
        <i className="cticon-back"></i>
        <span>{t('common.back')}</span>
      </a>
    );
  }
}

export default withTranslation()(CcBackBtn);