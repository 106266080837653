import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcRadio from '../components/form/radio';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcButton from '../components/form/button';
import CcModal from '../components/common/modal';

import style from './funds-origin.module.css';

class FundsOrigin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      taxCountries: [],
      taxModalVisible: false,
      taxModalSelection: null
    };

    // TODO: Add an IF to execute the push only when user's country of residence is Switzerland
    // if(user_country_of_residence_is_Switzerland)
    this.state.taxCountries.push({
      code: 'CH',
      name: 'Switzerland'
    });

    this.editingTaxCountryIx = -1;

    this.taxModalCountryRef = React.createRef();
    this.taxModalRadioRef = React.createRef();
    this.taxModalTaxNrRef = React.createRef();
    this.taxModalNoTaxReasonRef = React.createRef();

    this.deleteTaxCountry = this.deleteTaxCountry.bind(this);
    this.editTaxCountry = this.editTaxCountry.bind(this);
    this.confirmTaxCountry = this.confirmTaxCountry.bind(this);
    this.showTaxModal = this.showTaxModal.bind(this);
    this.hideTaxModal = this.hideTaxModal.bind(this);
  }

  deleteTaxCountry(ev, ix) {
    this.state.taxCountries.splice(ix, 1);
    this.setState({ taxCountries: this.state.taxCountries });
  }

  editTaxCountry(ev, ix) {
    this.editingTaxCountryIx = ix;
    this.taxModalCountryRef.current.matchItemByValue(this.state.taxCountries[ix].code, () => {
      this.taxModalRadioRef.current.setState({ selection: this.state.taxCountries[ix].taxNrOption }, () => {
        this.taxModalTaxNrRef.current.setState({ value: this.state.taxCountries[ix].taxNr || '' });
        this.taxModalNoTaxReasonRef.current.setState({ value: this.state.taxCountries[ix].noNrReason || '' });
        this.setState({ taxModalVisible: true });
      });
    });
  }

  confirmTaxCountry(ev) {
    const selCountry = this.taxModalCountryRef.current.state.selectedItem;
    const selOption = this.taxModalRadioRef.current.state.selection;
    
    if (selCountry && selOption) {
      let valid = false;
      let taxCountry = {
        code: selCountry.value,
        name: selCountry.label,
        taxNrOption: selOption
      }

      if (selOption === 'haveTaxNr') {
        if (this.taxModalTaxNrRef.current.state.value) {
          taxCountry.taxNr = this.taxModalTaxNrRef.current.state.value;
          valid = true;
        }
      }
      else if (selOption === 'unavailableTaxNr') {
        if (this.taxModalNoTaxReasonRef.current.state.value) {
          taxCountry.noNrReason = this.taxModalNoTaxReasonRef.current.state.value;
          valid = true;
        }
      }
      else {
        valid = true;
      }

      if (valid) {
        if (this.editingTaxCountryIx > -1) {
          this.state.taxCountries[this.editingTaxCountryIx] = taxCountry;
        }
        else {
          this.state.taxCountries.push(taxCountry);
        }

        this.setState(
          { taxCountries: this.state.taxCountries },
          this.hideTaxModal
        );
      }
    }
  }

  showTaxModal(ev) {
    ev.preventDefault();
    this.setState({ taxModalVisible: true });
  }

  hideTaxModal(ev) {
    ev && ev.preventDefault();

    this.setState({ taxModalVisible: false });
    this.editingTaxCountryIx = -1;
    this.taxModalCountryRef.current.setState({ selectedItem: null });
    //this.taxModalCountryRef.current.resetFilter();
    this.taxModalRadioRef.current.setState({ selection: null });
    this.taxModalTaxNrRef.current.setState({ value: '' });
    this.taxModalNoTaxReasonRef.current.setState({ value: '' });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="financial-info" showPlatform={this.props.showPlatform} step="9" />
        <main className="main">
          <form autoComplete="off">
            <section>
              <div className="container">
                <h1>{t('financialInfo.pageTitle')} 2/2</h1>
                <h2>{t('fundsOrigin.pageSubtitle')}</h2>
                <div className="content">
                  <CcFieldSet>

                    <h3>{t('fundsOrigin.mainOrigin')}</h3>

                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcRadio
                          name="main-origin"
                          options={[
                            [t('fundsOrigin.salary'), 'salary'],
                            [t('fundsOrigin.savings'), 'savings'],
                            [t('fundsOrigin.sales'), 'sales', t('fundsOrigin.salesDesc')],
                            [t('fundsOrigin.family'), 'family'],
                            [t('fundsOrigin.inheritance'), 'inheritance'],
                            [t('common.other'), 'other']
                          ]}
                          customCssClass={style.fundsOrigin}
                        />
                      </CcFieldGroup>
                    </CcFieldRow>
                    <CcFieldRow>
                      <CcFieldGroup>
                        <CcSelectBox
                          name="origin-country"
                          label={t('fundsOrigin.originCountry')}
                          items={[
                            { label: 'Switzerland', value: 'CH' },
                            { label: 'Austria', value: 'AT' },
                            { label: 'Germany', value: 'DE' },
                            { label: 'Denmark', value: 'DK' },
                            { label: 'United Kingdom', value: 'UK' }
                          ]}
                        />
                      </CcFieldGroup>
                      <CcFieldGroup>
                        <CcInput name="bank" label={t('fundsOrigin.originBank')} />
                      </CcFieldGroup>
                    </CcFieldRow>
                  </CcFieldSet>
                </div>
              </div>
            </section>
            <section className="darker">
              <div className="container">
                <div className="content">
                  <h3>{t('fundsOrigin.taxCountry')}</h3>
                  {
                    this.state.taxCountries.length > 0 &&
                    <CcFieldSet>
                      {
                        this.state.taxCountries.map((taxCountry, ix) =>
                          <CcFieldRow key={ix}>
                            <CcFieldGroup>
                              <div className={style.taxCountry}>
                                <div className={style.taxCountryName}>
                                  {
                                    taxCountry.name + (taxCountry.taxNr ? ' (' + (taxCountry.code === 'CH' ? 'OASI' : 'TIN') + ': ' + taxCountry.taxNr + ')' : '')
                                  }
                                </div>
                                <div className={style.taxCountryTools}>
                                  <i className={'cticon-pencil ' + style.edit} title={t('common.change')} onClick={ev => this.editTaxCountry(ev, ix)} />
                                  {
                                    this.state.taxCountries.length > 1 &&
                                    <i className={'cticon-trash-can ' + style.delete} title={t('common.remove')} onClick={ev => this.deleteTaxCountry(ev, ix)} />
                                  }
                                </div>
                                <input type="hidden" name={'tax-country-' + ix} value={taxCountry.code} />
                              </div>
                            </CcFieldGroup>
                          </CcFieldRow>
                        )
                      }
                    </CcFieldSet>
                  }
                  <div className={style.addTaxCountry}>
                    <a href="/" onClick={this.showTaxModal} data-modal="taxCountryModal">
                      {t(this.state.taxCountries.length > 0 ? 'fundsOrigin.addAnotherCountry' : 'fundsOrigin.addCountry')}
                      <i className="cticon-plus---2" />
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container">
                <div className="content">
                  <div className="btnHolder">
                    <CcButton text={t('common.continue')} link="traderscard-intro" />
                  </div>
                </div>
              </div>
            </section>
          </form>
        </main>
        <CcFooter />

        <CcModal
          id="taxCountryModal"
          title={t('fundsOrigin.addTaxCountry')}
          show={this.state.taxModalVisible}
          handleClose={this.hideTaxModal}
          backdropClose={true}
          customCssClass={style.taxModal}
        >
          <CcFieldSet>
            <CcFieldRow>
              <CcFieldGroup>
                <CcSelectBox
                  label={t('contactInfo.country')}
                  name="taxModalCountry"
                  items={[
                    { label: 'Switzerland', value: 'CH' },
                    { label: 'Austria', value: 'AT' },
                    { label: 'Germany', value: 'DE' },
                    { label: 'Denmark', value: 'DK' },
                    { label: 'United Kingdom', value: 'UK' }
                  ]}
                  ref={this.taxModalCountryRef}
                  // Force rerendering to show/hide additional fields (yourTaxNr, noTaxReason)
                  onChange={ev => this.setState(this.state)}
                />
              </CcFieldGroup>
              <CcFieldGroup cssClass="void" />
            </CcFieldRow>
            <CcFieldRow>
              <CcFieldGroup>
                <CcRadio
                  options={[
                    [t([undefined, 'CH'].includes(this.taxModalCountryRef.current?.state.selectedItem?.value) ? 'fundsOrigin.haveOASI' : 'fundsOrigin.haveTIN'), 'haveTaxNr'],
                    [t('fundsOrigin.notRequiredTaxNr'), 'notRequiredTaxNr'],
                    [t('fundsOrigin.notAssignedTaxNr'), 'notAssignedTaxNr'],
                    [t([undefined, 'CH'].includes(this.taxModalCountryRef.current?.state.selectedItem?.value) ? 'fundsOrigin.unavailableOASI' : 'fundsOrigin.unavailableTIN'), 'unavailableTaxNr']
                  ]}
                  cssClass="vertical"
                  ref={this.taxModalRadioRef}
                  // Force rerendering to show/hide additional fields (yourTaxNr, noTaxReason)
                  onChange={ev => this.setState(this.state)}
                />
              </CcFieldGroup>
            </CcFieldRow>
            <CcFieldRow customCssClass={this.taxModalRadioRef.current?.state.selection === 'haveTaxNr' ? '' : style.hidden}>
              <CcFieldGroup>
                <CcInput
                  label={t([undefined, 'CH'].includes(this.taxModalCountryRef.current?.state.selectedItem?.value) ? 'fundsOrigin.yourOASI' : 'fundsOrigin.yourTIN')}
                  inputmode="numeric"
                  ref={this.taxModalTaxNrRef}
                />
              </CcFieldGroup>
            </CcFieldRow>
            <CcFieldRow customCssClass={this.taxModalRadioRef.current?.state.selection === 'unavailableTaxNr' ? '' : style.hidden}>
              <CcFieldGroup>
                <CcInput
                  label={t('fundsOrigin.noTaxReason')}
                  ref={this.taxModalNoTaxReasonRef}
                />
              </CcFieldGroup>
            </CcFieldRow>
            {
              this.taxModalRadioRef.current?.state.selection !== 'haveTaxNr' &&
              this.taxModalRadioRef.current?.state.selection !== 'unavailableTaxNr' &&
              <div className={style.taxModalPlaceholder} />
            }
          </CcFieldSet>
          <div className={style.taxModalBtnHolder}>
            <CcButton text={t('common.confirm')} cssClass="dark" onClick={this.confirmTaxCountry} />
          </div>
        </CcModal>

      </>
    );
  }
}

export default withTranslation()(FundsOrigin);