import React from 'react';
import style from './logo.module.css';
import cLogo from '../../assets/img/clogo.svg';
import ctLogo from '../../assets/img/ctlogo.svg';

class CcLogo extends React.Component {
  render() {
    let cssClass = style.logo;

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        <a className={style.ctlogo} href="/"><img src={ctLogo} width="128" alt="Cornèrtrader" /></a>
        <a className={style.clogo} href="/"><img src={cLogo} width="26" alt="Cornèr" /></a>
      </div>
    );
  }
}

export default CcLogo;