import React from 'react';
import style from './tooltip.module.css';

class CcTooltip extends React.Component {

  constructor(props) {
    super(props);
    this.state = { boxStyle: null };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
  }

  handleMouseEnter(ev) {
    let icon = ev.target;
    let box = icon.parentElement.querySelector('[class^=tooltip_box]');
    let iconRect = icon.getBoundingClientRect();
    let winWidth = window.innerWidth;
    let boxWidth = box.offsetWidth;
    let boxBottom = icon.parentElement.getBoundingClientRect().bottom - iconRect.bottom + iconRect.height + 7;
    let boxLeft = null;
    let marginOffset = 32;

    // Shift the tooltip box to the right, if the icon is too close to the left margin of the window
    if (iconRect.left - boxWidth / 2 < marginOffset) {
      boxLeft = (iconRect.left - marginOffset) * -1;
    }
    // Shift the tooltip box to the left, if the icon is too close to the right margin of the window
    else if (iconRect.left + iconRect.width + boxWidth / 2 + marginOffset > winWidth) {
      boxLeft = (boxWidth - (winWidth - iconRect.left - marginOffset)) * -1;
    }

    if (boxLeft === null) {
      this.setState({ boxStyle: { left: '', transform: '', bottom: boxBottom + 'px' } });
    }
    else {
      this.setState({ boxStyle: { left: boxLeft + 'px', transform: 'none', bottom: boxBottom + 'px'} });
    }
  }

  render() {
    let cssClass = style.tooltip;
    
    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <span className={cssClass}>
        <span className={style.icon} onMouseEnter={ev => this.handleMouseEnter(ev)}>?</span>
        <span className={style.box} style={this.state.boxStyle}>
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default CcTooltip;