import React from 'react';
import style from './selectbox.module.css';

class CcSelectBox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownVisible: false
    };

    this.items = this.props.items.map((item, ix) => {
      let rez = {};

      if (typeof item === 'object') {
        rez = item;
      }
      else {
        rez.label = item;
        rez.value = ix;
      }

      if (this.props.value === rez.value) {
        this.state.selectedItem = rez;
      }

      return rez;
    });

    this.state.filteredItems = this.items;

    this.inputRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.dropdownSearchRef = React.createRef();

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.toggleDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.toggleDropdown);
  }
  
  toggleDropdown(ev) {
    if (!this.dropdownRef.current.contains(ev.target)) {
      if (ev.target === this.inputRef.current && !this.state.dropdownVisible) {
        this.setState(
          { dropdownVisible: !this.state.dropdownVisible },
          () => {
            if (this.state.dropdownVisible && this.props.search) {
              setTimeout(() => this.dropdownSearchRef.current.select(), 1);
            }
          }
        );
      }
      else if (this.state.dropdownVisible) {
        this.setState({ dropdownVisible: false });
      }
    }
  }
  
  handleSearch(ev) {
    let filtered = this.items.filter(item => {
      let label = (Array.isArray(item.label) ? item.label.join(' ') : item.label);
      return label.toLowerCase().includes(ev.target.value);
    });

    this.setState({
      filteredItems: filtered
    });
  }
  
  handleSelect(ev, item) {
    this.setState(
      {
        selectedItem: item,
        dropdownVisible: false
      },
      () => {
        this.resetFilter();

        if (this.props.onChange) {
          this.props.onChange(ev, this);
        }
      }
    );
  }

  matchItemByValue(value, stateCallback) {
    let matched = false;

    for (const item of this.items) {
      if (item.value == value) {
        matched = true;
        this.setState({ selectedItem: item }, () => {
          stateCallback && stateCallback();
        });
        break;
      }
    }

    if (!matched) {
      stateCallback && stateCallback();
    }
  }

  resetFilter() {
    this.setState({ filteredItems: this.items });
    setTimeout(() => {
      if (this.dropdownSearchRef.current) {
        this.dropdownSearchRef.current.value = '';
      }
    }, 250);
  }
  
  render() {
    let cssClass = style.field;

    if (this.props.invalid) {
      cssClass = style.invalid;
    }
    else if (this.props.valid) {
      cssClass = style.valid;
    }

    if (this.props.currency) {
      cssClass += ' ' + style.currency;
    }

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    if (this.state.dropdownVisible) {
      cssClass += (' ' + style.ddShown);
    }

    return (
      <div className={cssClass}>
        {
          this.state.selectedItem && this.state.selectedItem.icon &&
          <img src={`./img/${this.state.selectedItem.icon}`} className={style.icon} />
        }
        <input
          id={this.props.id}
          type="text"
          value={this.state.selectedItem ? (Array.isArray(this.state.selectedItem.label) ? this.state.selectedItem.label[this.props.previewIndex || 0] : this.state.selectedItem.label) : ''}
          readOnly={true}
          placeholder={this.props.label ? ' ' : (this.props.placeholder ? this.props.placeholder : '')}
          ref={this.inputRef}
        />
        <label className={style.label}>{this.props.label}</label>
        {
          this.props.placeholder && this.props.label &&
          <span className={style.placeholder}>{this.props.placeholder}</span>
        }
        <i className={style.ddIcon + ' cticon-expand-arrow'}></i>
        {
          this.props.currency &&
          <span className={style.currencyCode}>{this.props.currency}</span>
        }
        {
          this.props.errorMsg &&
          <span className={style.error}>{this.props.errorMsg}</span>
        }
        <input type="hidden" name={this.props.name} value={this.state.selectedItem ? this.state.selectedItem.value : ''} />
        <div className={style.dropdown} ref={this.dropdownRef}>
          {
            this.props.search &&
            <div className={style.dropdownSearch}>
              <input type="text" placeholder={typeof this.props.search === 'string' ? this.props.search : null} ref={this.dropdownSearchRef} onChange={this.handleSearch} />
              <i className="cticon-search"></i>
            </div>
          }
          <div className={style.items}>
            {
              this.state.filteredItems.map(
                (item, ix) =>
                  <div key={ix} onClick={ev => this.handleSelect(ev, item)}>
                    {item.icon && <img src={`./img/${item.icon}`} className={style.icon} />}
                    {Array.isArray(item.label) ? item.label.join(' ') : item.label}
                  </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CcSelectBox;