
export const TranslatEN = {
  common: {
    haveQuestions: 'Do you have questions?',
    back: 'Back',
    continue: 'Continue',
    change: 'Change',
    yes: 'Yes',
    no: 'No',
    none: 'None',
    other: 'Other',
    confirm: 'Confirm',
    remove: 'Remove',
    optional: 'optional',
    skipStep: 'Skip this step',
    backHome: 'Back to Home',
    cancelOnboarding: 'Cancel onboarding',
    search: 'Search',
    disclaimer: 'Disclaimer',
    impressum: 'Impressum',
    copyrightAddr: 'Tödistrasse 27, CH-8002, Zürich - Switzerland'
  },
  platform: {
    pageTitle: 'Your preferred platform',
    pageSubtitle: 'Select the platform that best matches your investment style.',
    account: 'Platform Account',
    flex: 'FLEXibility',
    flexSubTitle: 'Active trading and investments',
    flexDesc: 'The complete work station for multi-asset trading',
    flexInfo1: 'Choose among more than 30’000 instruments',
    flexInfo2: 'With charting & analytics tools',
    invest: 'Invest',
    investSubTitle: 'Medium & long term investments',
    investDesc: 'The all-you-need platform for investors',
    investInfo1: 'Simple & user-friendly interface',
    investInfo2: 'Relevant and popular investment themes',
    advance: 'Advance',
    advanceSubTitle: 'Professional trading',
    advanceDesc: 'The fully customisable trading platform',
    advanceInfo1: 'Vast customization options and extended functions',
    advanceInfo2: 'Innovative risk management tools',
    advanceInfo3: 'Multi-screen support for up to 6 monitors',
    none: 'None',
    products: {
      EQ: 'Stocks',
      EQo: 'Stock Options',
      Bo: 'Bonds',
      MF: 'Mutual Funds',
      ETF: 'Exchange Traded Fund',
      ETP: 'Exchange Traded Products',
      ETN: 'Exchange Traded Note',
      ETC: 'Exchange Traded Commodity',
      FX: 'Forex',
      FXo: 'Forex Options',
      CFX: 'Crypto FX',
      CFD: 'Contracts for Difference',
      CFDo: 'CFD Options',
      FU: 'Futures',
      Co: 'Contract Options'
    },
    more: 'Find out more',
    select: 'Select',
    selected: 'Selected',
    confirm: 'I confirm that I am aware of the risks associated with the use of online trading services and that I have adequate knowledge and experience in financial matters to deal with the instruments accessible through Cornèrtrader. Here you find the <Requirements>requirements for the use of Cornèrtrader</Requirements>.',
    sureLeave: 'Are you sure you want to leave?',
    leaveWarning: 'If you leave the onboarding for your Cornèrtrader account now, your data won’t be saved.',
    continueOnboarding: 'Continue onboarding',
    tryFreeDemo: 'Try with a demo account',
    yesCancel: 'Yes, I want to leave'
  },
  contactInfo: {
    pageTitle: 'Contact information',
    pageSubtitle: 'Welcome to our secure online process. Let’s get started!',
    title: 'Title',
    mr: 'Mr',
    mrs: 'Mrs',
    firstName: 'First name',
    lastName: 'Surname',
    email: 'E-mail address',
    country: 'Country',
    postCode: 'Postcode',
    nap: 'NAP',
    city: 'City',
    streetAndNumber: 'Street and number',
    phoneNr: 'Phone number',
    mobile: 'Mobile',
    landline: 'Landline',
    emailRequired: 'Please enter a valid email address',
    phoneRequired: 'Only number format please, e.g.: 7812234567',
    gtcAgree: 'I accept the <Terms>General Terms and Conditions</Terms> and request the opening of an account by accepting the <Disclaimer>Disclaimer</Disclaimer>.'
  },
  personalInfo: {
    pageTitle: 'Personal information',
    pageSubtitle: 'Tell us more about you. We only ask for details required by law.',
    birthDate: 'Date of birth',
    birthCountry: 'Country of birth',
    birthCity: 'City of birth',
    nationality: 'Nationality',
    maritalStatus: 'Marital status',
    residenceCountry: 'Country of residence',
    residenceCity: 'City of residence',
    residenceAddress: 'Address of residence',
    domicileAddress: 'Actual address of domicile',
    chResidencePermit: 'Swiss Residence Permit',
    diffAddress: 'My correspondence address is different from my address of residence.',
    countryNotSupported: 'Unfortunately, we are currently not providing our trading platforms in <Country />.'
  },
  correspondence: {
    pageTitle: 'Correspondence address',
    pageSubtitle: 'If different from your residence address, insert your correspondence address.'
  },
  declarations: {
    pageTitle: 'Additional Mandatory Information',
    pageSubtitle: 'We need some information to open your account in full transparency.',
    declarationOwner: 'Declaration of the identity of the beneficial owner',
    assets: 'In accordance with Article 27 of the Agreement on the Swiss banks\' code of conduct with regard to the exercise of the due diligence(CDB), the contracting partner hereby declares that the assets deposited in the account at the bank...',
    assetsDesc: 'It is a criminal offence to deliberately provide false information on this form (article 251 of the Swiss Criminal Code, document forgery).',
    soleOwner: '...belong only to me and I declare that I’m the sole beneficial owner.',
    otherOwners: '...belong to the following person or persons who is/are the beneficial owner(s).',
    beneficialOwner: 'Beneficial owner',
    addOwner: 'Add another beneficial owner',
    politicalExposure: 'Declaration of political exposure',
    politicalExposed: 'Are you a politically exposed person?',
    politicalExposedDetails: 'For example: a holder of executive, legislative or judicial powers at national level, such as a member of parliament, head of state, a senior civil servant, senior army officer or senior party official, members of the Board of Directors or senior management of state-run enterprises of national importance, managers in interstate organisations or international sporting federations, natural persons with personal, family or business ties to a politically exposed person, etc.',
    politicalExposedDesc: 'Senior public office or function held currently or previously abroad or in Switzerland at national or international level.',
    politicalPosition: 'Political position'
  },
  declarationUS: {
    pageTitle: 'U.S. person',
    pageSubtitle: 'We cannot open an account for “U.S. persons” as defined under American tax law. That’s why we have to ask you more questions regarding your U.S. tax status certification and the identity of the beneficial owner.',
    compliance: 'In compliance with the requirements under U.S. withholding tax regulations, and to enable the Bank to correctly determine the status and qualification of the holder of the account and the beneficial owner thereof, if different from the holder (for the purposes of U.S. withholding tax), as a “non-U.S. person” or “U.S. person” the aforementioned account holder hereby confirms the following to the bank:',
    statusCert: 'Status certification',
    statusCertDesc: 'With regard to the account indicated above held at the bank, please answer the following questions.',
    usCitizen: 'Are you a U.S. citizen?',
    usCitizenBenefOwner: 'Are you as the account holder or the beneficial owner(s) thereof U.S. citizens?',
    usCitizenDesc: '(sole or dual citizenship)',
    usResident: 'Are you a lawful permanent U.S. resident?',
    usResidentBenefOwner: 'Are you as the account holder or the beneficial owner(s) thereof lawful permanent U.S. residents?',
    usResidentDesc: '(e.g. green card, substantial physical presence test)',
    usBorn: 'Were you born on US territory?',
    usBornBenefOwner: 'Were you as the account holder or the beneficial owner(s) thereof born on U.S. territory?',
    usTaxpayer: 'Are you considered a U.S. taxpayer for any other reason?',
    usTaxpayerBenefOwner: 'Are you as the account holder or the beneficial owner(s) thereof considered  U.S. taxpayers for any other reason?',
    usTaxpayerDesc: '(e.g. dual resident, spouse filing jointly, renunciation of U.S. citizenship, residency permit following long and frequent presence in the U.S., or other)',
    fatca: 'To determine the status of “non-U.S. person”, according to FATCA, the bank is obliged to ask for more information with particular reference to the following indications:',
    fatcaData1: 'Place of residence or postal address in the U.S.',
    fatcaData2: 'One or more phone numbers in the U.S.',
    fatcaData3: 'Power of Attorney or authorization signature currently in force in favor of a U.S. person',
    fatcaData4: 'Standing order for the transfer of funds to an account held in the U.S.',
    fatcaData5: 'Place of birth in the U.S.',
    fatcaConfirm: 'Do you meet one of these indicia?',
    irsConfirm: 'For the IRS, please declare once more that your assets belong solely to you.',
    irsConfirmBenefOwner: 'For the IRS, please declare once more the name of the beneficial owner of your assets.',
    irsSoleOwner: 'I hereby state pursuant to the principles laid out by the U.S. Internal Revenue Service (IRS) that I am the sole beneficial owner of the assets and income to which this form refers.',
    irsBenefOwner: 'As the account holder, I hereby state pursuant to the principles laid out by the U.S. Internal Revenue Service (IRS) that the beneficial owner of the assets and income to which this form refers is:',
    changeNonUS: 'Change of “non-U.S. person” status',
    changeNonUSDesc: 'If, pursuant to U.S. tax law, the “non-U.S. person” status should change over time, the account holder is required to inform the bank immediately of such a change in writing.',
    sorry: 'Unfortunately we cannot open an account for US persons.'
  },
  occupation: {
    pageTitle: 'Professional information',
    pageSubtitle: 'Questions about your job situation',
    current: 'What’s your current position?',
    employed: 'Employed',
    selfEmployed: 'Self-employed',
    unemployed: 'Unemployed',
    student: 'Student',
    retired: 'Retired',
    sector: 'Sector of activity',
    occupation: 'Occupation (e.g. Salesperson)',
    role: 'Specific role (e.g. Manager)',
    companyName: 'Company name',
    yourCompany: 'Your company',
    activityType: 'Type of activity (e.g. Consulting)',
    selfRole: 'Specific role (e.g. CEO)',
    internRole: 'Specific role (e.g. Sales intern)',
    currEmployedOrIntership: 'Are you currently employed or doing an intership?',
    previouslyEmployed: 'What was your professional situation before your retirement?'
  },
  financialInfo: {
    pageTitle: 'Financial information',
    pageSubtitle: 'Give us an overview of your financial situation',
    currency: 'Currency',
    accountCurrency: 'Account currency',
    fundsEstimation: 'Estimation of your funds',
    annualIncome: 'Annual income',
    netAssets: 'Net assets',
    netApproximate: 'Approximate amount',
    transferredFunds: 'Funds to be transferred within 12 months',
    currencyConvertor: 'Currency convertor'
  },
  fundsOrigin: {
    pageSubtitle: 'Identify the origin of your funds',
    mainOrigin: 'Main origin of your funds',
    salary: 'Salary/pension',
    savings: 'Savings',
    sales: 'Sales of assets',
    salesDesc: '(e.g. real estate, art etc.)',
    family: 'Family assets',
    inheritance: 'Inheritance/donation',
    originCountry: 'Country of origin',
    originBank: 'Bank of origin',
    taxResidence: 'Tax residence',
    taxCountry: 'Country of tax residence',
    addCountry: 'Add country',
    addAnotherCountry: 'Add another country',
    addTaxCountry: 'Add a country of tax residence',
    haveOASI: 'I have an OASI number or TIN (tax identification number)',
    haveTIN: 'I have a TIN (tax identification number)',
    notRequiredTaxNr: 'I’m not required to obtain a TIN',
    notAssignedTaxNr: 'My country of tax residence doesn’t assign TIN',
    unavailableOASI: 'I’m otherwise unable to provide an OASI or TIN',
    unavailableTIN: 'I’m otherwise unable to provide a TIN',
    yourOASI: 'Your OASI number',
    yourTIN: 'Your TIN',
    noTaxReason: 'Reason'
  },
  tradersCardIntro: {
    pageTitle: 'TradersCard',
    pageSubtitle: 'Get a payment card directly connected with your trading account',
    hlight1: 'Your trading profits on a VISA debit card',
    hlight2: 'iCornèr app: manage your card with a click',
    hlight3: 'First year free of charge, then 40 CHF/year',
    addCard: 'Add the TradersCard',
    skip: 'Continue without the card'
  },
  tradersCard: {
    pageTitle: 'Get your TradersCard for free',
    pageSubtitle: 'The prepaid card that gives you the greatest freedom',
    cardCurrency: 'Currency of the card',
    rentingOrOwning: 'Are you renting or owning your home?',
    renting: 'Renting',
    owning: 'Owning',
    homeAnnualCost: 'Annual cost for rent or home loan',
    travelInsurance: 'Travel insurance',
    travelInsuranceDesc: 'Worldwide for an unlimited number of trips per year includes cancellation expenses, travel interruption, travel assistance and deductible coverage for car rentals.',
    individualInsurance: 'Individual',
    individualInsurancePrice: '35CHF/year',
    individualInsuranceDesc: 'Coverage: CHF 10,000 per person and event',
    familyInsurance: 'Family',
    familyInsurancePrice: '60CHF/year',
    familyInsuranceDesc: 'Coverage: CHF 10,000 per person, CHF 40,000 per event',
    notice: 'Insurance benefits will be paid exclusively in accordance with the General Terms of Insurance (GTI), which can be accessed or ordered at any time at cornercard.ch/e/gtc and which will be sent to me together with the confirmation of insurance. By signing this document, I confirm that I have acknowledged and understood the GTI and that I accept all the applicable terms.',
    iCornerFree: 'iCornèr app (for free)',
    accessPortal: 'Access to our client portal',
    portalDesc: 'Manage your Cornèr account(s) with a single login, make secure online purchases and get electronic monthly statements.',
    request: 'Request TradersCard',
    skip: 'Continue without the card'
  },
  confirm: {
    pageTitle: 'Thank you, we are ready!',
    pageSubtitle: 'Make sure all your data is filled in correctly and download your contract.',
    selectedPlatform: 'Selected platform',
    cta: 'Confirm and download',
    yourData: 'Your data',
    politicalExposure: 'Political exposure',
    politicalExposed: 'Politically exposed person'
  },
  videoAuth: {
    pageTitle: 'Sign your contract online in a minute',
    pageSubtitle: 'The fastest way to verify your identity is through video identification, completely free of charge.',
    start: 'Start video authentication',
    serviceHours: 'Service hours: MO–SA, 7.00 a.m.–10 p.m.',
    notification: 'The video identification is only available during our service hours. You’ll get an e-mail with a link so <B>you can do the video identification later</B>.',
    classicWay: 'Print and sign on paper',
    printContract: 'You prefer the offline way? Print your contract and follow the instructions on its cover page. Then send us your signed contract by post.'
  },
  contract: {
    pageTitle: 'Your contract is ready to be signed',
    pageSubtitle: 'Print your contract and follow the instructions on its cover page. Then send us your signed contract along with the required documents by post.',
    forward: 'Forward contract to your e-mail inbox'
  },
  congratulation: {
    pageTitle: 'Congratulations!',
    pageSubtitle: 'You have successfully completed the video identification. We will get in touch with you within the next few days.',
    discoverReward: 'Discover our referral program',
    recommend: 'Recommend Cornèrtrader to your friends and you’ll get a trading bonus of <B>CHF 100</B> as a reward!',
    discover: 'Discover',
    letsStart: 'Let’s start'
  },
  
  // Demo screens
  demo: {
    referral: {
      pageTitle: 'Welcome to Cornèrtrader',
      pageSubtitle: 'You were invited to the Cornèrtrader by someone. Please, enter the name of this person.',
      firstName: 'Name of the person who referred you',
      lastName: 'Surname of the person who referred you',
      getStarted: 'Get started'
    },
    individual: {
      pageTitle: 'Contact information',
      pageSubtitle: 'We’re happy you’re opening an account! Let’s start with the most important: what’s your name and how can we contact you?',
      safeData: 'Your data are safe with us.'
    },
    tradersCard: {
      pageTitle: 'TradersCard',
      pageSubtitle: 'Get a payment card directly connected with your trading account',
      hlight1: 'Your trading profits on a VISA debit card',
      hlight2: 'Pay with Apple Pay, Google Pay, Samsung Pay, Garmin Pay and Fitbit Pay',
      hlight3: 'First year free of charge, then 40 CHF/year',
      additionalServices: 'Additional services',
      iCorner: 'iCornèr (For Free)',
      iCornerDesc: 'The free Cornèrcard client portal. Card and data management with a click of the mouse and secure purchases online. Required to activate electronic monthly statements.',
      travelInsurance: 'Travel insurance',
      travelInsuranceDesc: 'The free Cornèrcard client portal. Card and data management with a click of the mouse and secure purchases online. Required to activate electronic monthly statements.',
      individualCoverage: 'Individual coverage',
      individualCoverageDesc: 'CHF 10‘000 per person and event<BR />Annual premium CHF 35',
      familyCoverage: 'Family coverage',
      familyCoverageDesc: 'CHF 10‘000 per person, CHF 40‘000 per event<BR />Annual premium CHF 49',
      notice: 'Important notice: Insurance benefits will be paid exclusively in accordance with the General Terms of Insurance (GTI), which can be accessed or ordered at any time at cornercard.ch/e/gtc and which will be sent to me together with the confirmation of insurance. By signing this document, I confirm that I have acknowledged and understood the GTI and that I accept all the applicable terms.',
      requiredInfo: 'Required information',
      requiredInfoDesc: 'TradersCard is issued by Cornèrcard. For opening an account with Cornèrcard, this additional information is required.',
      chooseCurrency: 'Choose a currency for your TradersCard',
      rentingOrOwning: 'Are you renting or owning your home?',
      renting: 'Renting',
      owning: 'Owning',
      homeAnnualCost: 'Annual cost for rent or home loan',
      request: 'Request TradersCard',
      continue: 'Continue without TradersCard'
    }
  }
};
