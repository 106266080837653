import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcRadio from '../components/form/radio';
import CcSelect from '../components/form/select';
import CcCheckbox from '../components/form/checkbox';
import CcButton from '../components/form/button';

import style from './individual.module.css';

class Individual extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="/" showPlatform={this.props.showPlatform} />
        <main className={style.main}>
          <div className="container">
            <h1>{t('demo.individual.pageTitle')}</h1>
            <h2>{t('demo.individual.pageSubtitle')}</h2>
            <div className="content">
              <form autoComplete="off">
                <CcFieldSet>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcRadio
                        name="gender"
                        value="M"
                        options={[
                          [t('contactInfo.mr'), 'M'],
                          [t('contactInfo.mrs'), 'F']
                        ]}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="firstname" label={t('contactInfo.firstName')} required />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <CcInput name="lastname" label={t('contactInfo.lastName')} value="Berger" required valid />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="email" label={t('contactInfo.email')} required invalid errorMsg={t('contactInfo.emailRequired')} />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <CcInput
                        type="tel"
                        name="phone-number"
                        pattern="[0-9]{2}[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}"
                        label={t('contactInfo.phoneNr')}
                        placeholder="00 000 00 00"
                        cssClass="prefixed"
                      >
                        <CcSelect
                          name="phone-prefix"
                          cssClass="prefix"
                          options={[
                            ['41', '+41 — Switzerland'],
                            ['43', '+43 — Austria'],
                            ['44', '+44 — United Kingdom'],
                            ['45', '+45 — Denmark'],
                            ['49', '+49 — Germany']
                          ]}
                        />
                      </CcInput>
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="terms" customCssClass={style.terms}>
                        <Trans i18nKey="contactInfo.gtcAgree" components={{ Terms: <a href="/" target="_blank" />, Disclaimer: <a href="/" target="_blank" /> }} />
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>

                </CcFieldSet>
                <div className={style.btnHolder}>
                  <CcButton text={t('common.continue')} type="submit" />
                </div>
                <div className={style.safeData}>
                  <i className="cticon-security-shield-green"></i>
                  {t('demo.individual.safeData')}
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Individual);