import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import './assets/css/fonts.css';

// Components
import CcHeader from './components/common/header';
import CcFooter from './components/common/footer';

// Screens
import Platform from './screens/platform';
import ContactInfo from './screens/contact-info';
import PersonalInfo from './screens/personal-info';
import Correspondence from './screens/correspondence';
import Declarations from './screens/declarations';
import DeclarationUS from './screens/declaration-us';
import Occupation from './screens/occupation';
import FinancialInfo from './screens/financial-info';
import FundsOrigin from './screens/funds-origin';
import TradersCardIntro from './screens/traderscard-intro';
import TradersCard from './screens/traderscard';
import Confirm from './screens/confirm';
import VideoAuth from './screens/video-auth';
import Contract from './screens/contract';
import Congratulation from './screens/congratulation';

// Demo
import style from './demo/demo.module.css';
import DemoComponents from './demo/components';
import DemoPlatform from './demo/platform';
import DemoReferral from './demo/referral';
import DemoIndividual from './demo/individual';
import DemoTradersCard from './demo/traderscard';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Screens />} />
        <Route path="platform" element={<Platform />} />
        <Route path="contact-info" element={<ContactInfo showPlatform />} />
        <Route path="personal-info" element={<PersonalInfo showPlatform />} />
        <Route path="correspondence" element={<Correspondence showPlatform />} />
        <Route path="declarations" element={<Declarations showPlatform />} />
        <Route path="declaration-us" element={<DeclarationUS showPlatform />} />
        <Route path="occupation" element={<Occupation showPlatform />} />
        <Route path="financial-info" element={<FinancialInfo showPlatform />} />
        <Route path="funds-origin" element={<FundsOrigin showPlatform />} />
        <Route path="traderscard-intro" element={<TradersCardIntro showPlatform />} />
        <Route path="traderscard" element={<TradersCard showPlatform />} />
        <Route path="confirm" element={<Confirm />} />
        <Route path="video-auth" element={<VideoAuth />} />
        <Route path="contract" element={<Contract />} />
        <Route path="congratulation" element={<Congratulation />} />

        {/* DEMO */}
        <Route path="components" element={<DemoComponents />} />
        <Route path="demo-platform" element={<DemoPlatform />} />
        <Route path="demo-individual" element={<DemoIndividual showPlatform />} />
        <Route path="demo-referral" element={<DemoReferral />} />
        <Route path="demo-traderscard" element={<DemoTradersCard />} />
      </Routes>
    </Router>
  );
}

class Screens extends React.Component {
  render() {
    return (
      <>
        <CcHeader />
        <main className="main">
          <div className={style.demo}>
            <div className={style.container}>
              <h3>Onboarding</h3>
              <a href="platform">Preferred platform</a><br />
              <a href="contact-info">Contact information</a><br />
              <a href="personal-info">Personal information</a><br />
              <a href="correspondence">Correspondence address</a><br />
              <a href="declarations">Declarations</a><br />
              <a href="declaration-us">US person</a><br />
              <a href="occupation">Professional information</a><br />
              <a href="financial-info">Financial information</a><br />
              <a href="funds-origin">Funds origin</a><br />
              <a href="traderscard-intro">TradersCard presentation</a><br />
              <a href="traderscard">TradersCard form</a><br />
              <a href="confirm">Confirm entries</a><br />
              <a href="video-auth">Video authentication</a><br />
              <a href="contract">Forward contract</a><br />
              <a href="congratulation">Congratulation</a><br />

              <h3>Demo</h3>
              <a href="components">Components</a><br />
              <a href="demo-platform">Platform selection</a> <i className={style.demoScreen}>(old demo)</i><br />
              <a href="demo-referral">Referral <i className={style.demoScreen}>(demo screen)</i></a><br />
              <a href="demo-individual">Individual <i className={style.demoScreen}>(demo screen)</i></a><br />
              <a href="demo-traderscard">TradersCard <i className={style.demoScreen}>(old demo)</i></a>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default App;