import React from 'react';
import Slick from 'react-slick';

import 'slick-carousel/slick/slick.css';
import '../../assets/css/slick-theme.css';
import style from './slider.module.css';

class CcSlider extends React.Component {
  render() {
    let cssClass = style.slider;
    
    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        <Slick {...this.props.settings}>
          {this.props.children}
        </Slick>
      </div>
    );
  }
}

export default CcSlider;