import React from 'react';
import { withTranslation } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcInput from '../components/form/input';
import CcSelectBox from '../components/form/selectbox';
import CcButton from '../components/form/button';

import style from './correspondence.module.css';

class Correspondence extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="personal-info" showPlatform={this.props.showPlatform} step="4" />
        <main className="main">
          <div className="container">
            <h1>{t('correspondence.pageTitle')}</h1>
            <h2>{t('correspondence.pageSubtitle')}</h2>
            <div className="content">
              <form autoComplete="off">
                <CcFieldSet>

                  <div className={style.skip}>
                    <a href="declarations">{t('common.skipStep')}</a>
                  </div>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelectBox
                        name="country"
                        label={t('contactInfo.country')}
                        items={[
                          { label: 'Switzerland', value: 'CH' },
                          { label: 'Austria', value: 'AT' },
                          { label: 'Germany', value: 'DE' },
                          { label: 'Denmark', value: 'DK' },
                          { label: 'United Kingdom', value: 'UK' }
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <CcInput name="city" label={t('contactInfo.city')} cssClass="prefixedWithLabel">
                        <CcInput name="postcode" label={t('contactInfo.postCode')} cssClass="prefixWithLabel" />
                      </CcInput>
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="street" label={t('contactInfo.streetAndNumber')} />
                    </CcFieldGroup>
                  </CcFieldRow>

                </CcFieldSet>
                <div className={style.btnHolder}>
                  <CcButton text={t('common.continue')} link="declarations" />
                </div>
              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(Correspondence);