import React from 'react';
import style from './components.module.css';

// Components
import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcButton from '../components/form/button';
import CcCheckbox from '../components/form/checkbox';
import CcChecklist from '../components/form/checklist';
import CcInput from '../components/form/input';
import CcRadio from '../components/form/radio';
import CcSelect from '../components/form/select';
import CcSelectBox  from '../components/form/selectbox';
import CcList from '../components/common/list';
import CcTooltip from '../components/common/tooltip';
import CcModal from '../components/common/modal';
import CcPanel from '../components/common/panel';
import CcSlider from '../components/common/slider';

class DemoComponents extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modals: {}
    };
    
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  
  showModal(ev) {
    ev.preventDefault();
    
    if (ev.target.dataset.modal) {
      this.state.modals[ev.target.dataset.modal] = { show: true };
      this.setState(this.state.modals);
    }
  }
  
  hideModal(ev) {
    const modalId = ev.target.closest('.modal-holder').id;
    
    ev.preventDefault();
    ev.stopPropagation();
    
    if (modalId) {
      this.state.modals[modalId] = { show: false };
      this.setState(this.state.modals);
    }
  }
  
  render() {
    return (
      <>
        <CcHeader prev="/" />
        <main className="main">
          <div className={style.demo}>
            <div className={style.container}>
              <div className={style.components}>
                <h1>Components</h1>
                <CcFieldSet>
                
                  <h3 id="cc_button">Button</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton text="Primary button" />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
                        `<CcButton text="Primary button" />`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton text="Primary disabled" type="submit" disabled />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
                        `<CcButton text="Primary disabled" type="submit" disabled />`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton cssClass="secondary" text="Secondary button" />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="3" defaultValue={
`<CcButton
  cssClass="secondary"
  text="Secondary button"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton cssClass={['light', 'shadow']} text="Light button with shadow" />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="3" defaultValue={
`<CcButton
  cssClass={['light', 'shadow']}
  text="Light button with shadow"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton link="https://corner.ch" cssClass="dark" text="Dark link button" />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="4" defaultValue={
`<CcButton
  link="https://corner.ch"
  cssClass="dark"
  text="Dark link button"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.btnHolder}>
                        <CcButton text="Rotating icon" icon="loader" iconSpin />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
                        `<CcButton text="Rotating icon" icon="loader" iconSpin />`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_radio">Radio buttons</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      
                      <h4>Index of the selected option is used as field value</h4>

                      <div>
                        <CcRadio
                          name="demo-rb1"
                          options={[
                            'My index is 0',
                            'My index is 1'
                          ]}
                        />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="6" defaultValue={
`<CcRadio
  name="demo-rb1"
  options={[
    'My index is 0',
    'My index is 1'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      
                      <h4>Initial field value and items always stacked vertically</h4>

                      <div>
                        <CcRadio
                          name="demo-rb2"
                          value={1}
                          options={[
                            'Option A',
                            'Option B',
                            'Option C'
                          ]}
                          cssClass="vertical"
                        />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="8" defaultValue={
`<CcRadio
  name="demo-rb2"
  value={1}
  options={[
    'Option A',
    'Option B',
    'Option C'
  ]}
  cssClass="vertical"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      
                      <h4>Predefined values and optional description</h4>

                      <div>
                        <CcRadio
                          name="demo-rb3"
                          value="a"
                          options={[
                            ['Option A', 'a'],
                            ['Option B', 'b', 'Some description']
                          ]}
                        />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="7" defaultValue={
`<CcRadio
  name="demo-rb3"
  value="a"
  options={[
    ['Option A', 'a'],
    ['Option B', 'b', 'Some description']
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_checkbox">Checkbox</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox label="Pre-checked" checked />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
                        `<CcCheckbox label="Pre-checked" checked />`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox>
                        Content <b>elements</b> instead of <i>label</i> attribute
                      </CcCheckbox>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
`<CcCheckbox>
  Content <b>elements</b> instead of <i>label</i> attribute
</CcCheckbox>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox label="Round checkbox" cssClass="circle" />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="3" defaultValue={
`<CcCheckbox
  label="Round checkbox"
  cssClass="circle"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <h3 id="cc_checklist">Checklist</h3>

                  <CcFieldRow>
                    <CcFieldGroup>

                      <h4>Default checklist</h4>

                      <div>
                        <CcChecklist
                          name="demo-checklist1"
                          items={[
                            'Item A',
                            'Item B',
                            'Item C'
                          ]}
                        />
                        <div className={style.note}>
                          Each checkbox has it's name generated using the provided component name followed by a hyphen sign and the 0-based index of the item.
                          E.g. <i>demo-checklist1-0, demo-checklist1-1...</i>
                        </div>
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="7" defaultValue={
`<CcChecklist
  name="demo-checklist1"
  items={[
    'Item A',
    'Item B',
    'Item C'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>

                      <h4>Initial selections and items always stacked vertically</h4>

                      <div>
                        <CcChecklist
                          name="demo-checklist2"
                          items={[
                            { label: 'Item A' },
                            { label: 'Item B', checked: true },
                            { label: 'Item C', checked: true }
                          ]}
                          cssClass="vertical"
                        />
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="8" defaultValue={
`<CcChecklist
  name="demo-checklist2"
  items={[
    { label: 'Item A' },
    { label: 'Item B', checked: true },
    { label: 'Item C', checked: true }
  ]}
  cssClass="vertical"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <h3 id="cc_input">Input</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput label="Empty input with placeholder" placeholder="Placeholder text" />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="3" defaultValue={
`<CcInput
  label="Empty input"
  placeholder="Placeholder text"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput label="Prefilled and required" value="Lorem ipsum" required />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="5" defaultValue={
`<CcInput 
  label="Prefilled and required"
  value="Lorem ipsum"
  required
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput label="Invalid with error message" required invalid errorMsg="Required field" />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="4" defaultValue={
`<CcInput
  label="Invalid with error message"
  invalid
  errorMsg="Required field"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        label="Currency suffix"
                        currency="CHF"
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
                        `<CcInput label="Currency suffix" currency="CHF" />`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        label="Type (native, browser specific)"
                        type="date"
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
`<CcInput
  label="Type (native, browser specific)"
  type="date"
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <h3 id="cc_input_list">Input with datalist</h3>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        label="Datalist with strict validation"
                        datalist={[
                          'Africa',
                          'Americas',
                          'Asia',
                          'Europe',
                          'Oceania'
                        ]}
                        datalistStrict
                      />
                      <div className={style.note}>
                        The input value is the 0-based index of the selected item.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcInput
  label="Datalist with strict validation"
  datalist={[
    'Africa',
    'Asia',
    'Europe',
    'America',
    'Oceania'
  ]}
  datalistStrict
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        label="Datalist as array of objects"
                        datalist={[
                          { value: 501, label: 'Africa', desc: 'Population: 1.216 billion' },
                          { value: 502, label: 'Americas', desc: 'Population: 1.002 billion' },
                          { value: 503, label: 'Asia', desc: 'Population: 4.561 billion' },
                          { value: 504, label: 'Europe', desc: 'Population: 746.4 million' },
                          { value: 505, label: 'Oceania', desc: 'Population: 41,570,842' }
                        ]}
                      />
                      <div className={style.note}>
                        Both <i>value</i> and <i>desc</i> are optional, any of them can be ignored. When <i>value</i> is missing, the 0-based item index is used as value.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="9" defaultValue={
`<CcInput
  label="Datalist as array of objects"
  datalist={[
    { value: 501, label: 'Africa', desc: 'Population: 1.216 billion' },
    { value: 502, label: 'Americas', desc: 'Population: 1.002 billion' },
    { value: 503, label: 'Asia', desc: 'Population: 4.561 billion' },
    { value: 504, label: 'Europe', desc: 'Population: 746.4 million' },
    { value: 505, label: 'Oceania', desc: 'Population: 41,570,842' }
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        label="Datalist as array of arrays and default value"
                        datalist={[
                          [501, 'Africa'],
                          [502, 'Americas', 'North and South America'],
                          [503, 'Asia'],
                          [504, 'Europe'],
                          [505, 'Oceania', 'Australasia, Melanesia, Micronesia and Polynesia'],
                          ['I am used as value and label']
                        ]}
                        value={505}
                      />
                      <div className={style.note}>
                        Item syntax: <i>[value, &lt;label, &lt;desc&gt;&gt;]</i><br />
                        If item array contains only one element, it is used as value and label.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="12" defaultValue={
`<CcInput
  label="Datalist as array of arrays and default value"
  datalist={[
    [501, 'Africa'],
    [502, 'Americas', 'North and South America'],
    [503, 'Asia'],
    [504, 'Europe'],
    [505, 'Oceania', 'Australasia, Melanesia, Micronesia and Polynesia'],
    ['I am used as value and label']
  ]}
  value={505}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_select">Select</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelect
                        name="demo-select"
                        label="Simple select with pre-selected option"
                        value="Swiss"
                        options={[
                          'Austria',
                          'Germany',
                          'Switzerland',
                          'Denmark',
                          'United Kingdom'
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcSelect
  name="demo-select"
  label="Simple select with pre-selected option"
  value="Swiss"
  options={[
    'Austria',
    'Germany',
    'Switzerland',
    'Denmark',
    'United Kingdom'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcSelect
                        label="Options with value"
                        value="de"
                        options={[
                          ['at', 'Austria'],
                          ['de', 'Germany'],
                          ['ch', 'Switzerland'],
                          ['dk', 'Denmark'],
                          ['uk', 'United Kingdom']
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="9" defaultValue={
`<CcSelect
  label="Options with value"
  value="de"
  options={[
    ['at', 'Austria'],
    ['de', 'Germany'],
    ['ch', 'Switzerland'],
    ['dk', 'Denmark'],
    ['uk', 'United Kingdom']
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  
                  <h3 id="cc_selectbox">SelectBox</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Simple SelectBox</h4>
                      <CcSelectBox
                        name="demo-selectbox"
                        label="Country"
                        items={[
                          'Austria',
                          'Germany',
                          'Switzerland',
                          'Denmark',
                          'United Kingdom'
                        ]}
                      />
                      <div className={style.note}>
                        The field value is the 0-based index of the selected item.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcSelectBox
  name="demo-selectbox"
  label="Country"
  items={[
    'Austria',
    'Germany',
    'Switzerland',
    'Denmark',
    'United Kingdom'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Label-value pairs, default value, search</h4>
                      <CcSelectBox
                        name="demo-selectbox2"
                        label="Country"
                        search="Search"
                        value="CH"
                        items={[
                          { label: 'Austria', value: 'AT' },
                          { label: 'Germany', value: 'DE' },
                          { label: 'Switzerland', value: 'CH' },
                          { label: 'Denmark', value: 'DK' },
                          { label: 'United Kingdom', value: 'UK' }
                        ]}
                      />
                      <div className={style.note}>
                        Value of the <i>Search</i> property is used as placeholder for the search field.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcSelectBox
  name="demo-selectbox2"
  label="Country"
  search="Search"
  value="CH"
  items={[
    { label: 'Austria', value: 'AT' },
    { label: 'Germany', value: 'DE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Denmark', value: 'DK' },
    { label: 'United Kingdom', value: 'UK' }
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Item icon and label as array of values</h4>
                      <CcSelectBox
                        name="demo-selectbox3"
                        label="Phone prefix"
                        previewIndex="1"
                        items={[
                          { label: ['Switzerland', '+41'], value: '41', icon: 'flags/switzerland-24.png' },
                          { label: ['Austria', '+43'], value: '43', icon: 'flags/austria-24.png' },
                          { label: ['United Kingdom', '+44'], value: '44', icon: 'flags/united-kingdom-24.png' },
                          { label: ['Denmark', '+45'], value: '45', icon: 'flags/denmark-24.png' },
                          { label: ['Germany', '+49'], value: '49', icon: 'flags/germany-24.png' }
                        ]}
                      />
                      <div className={style.note}>
                        The optional <i>previewIndex</i> property contains the index of the label array item to be displayed after user selection. Default value is 0.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="12" defaultValue={
`<CcSelectBox
  name="demo-selectbox3"
  label="Phone prefix"
  previewIndex="1"
  items={[
    { label: ['Switzerland', '+41'], value: '41', icon: 'flags/switzerland-24.png' },
    { label: ['Austria', '+43'], value: '43', icon: 'flags/austria-24.png' },
    { label: ['United Kingdom', '+44'], value: '44', icon: 'flags/united-kingdom-24.png' },
    { label: ['Denmark', '+45'], value: '45', icon: 'flags/denmark-24.png' },
    { label: ['Germany', '+49'], value: '49', icon: 'flags/germany-24.png' }
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_select_pfx_input">Input prefixed with another component</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>SelectBox (without label) as prefix</h4>
                      <CcInput
                        type="tel"
                        pattern="[0-9]{2}[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}"
                        label="Phone number"
                        placeholder="00 000 00 00"
                        cssClass="prefixed"
                      >
                        <CcSelectBox
                          cssClass="prefix"
                          value="41"
                          previewIndex="1"
                          items={[
                            { label: ['Switzerland', '+41'], value: '41', icon: 'flags/switzerland-24.png' },
                            { label: ['Austria', '+43'], value: '43', icon: 'flags/austria-24.png' },
                            { label: ['United Kingdom', '+44'], value: '44', icon: 'flags/united-kingdom-24.png' },
                            { label: ['Denmark', '+45'], value: '45', icon: 'flags/denmark-24.png' },
                            { label: ['Germany', '+49'], value: '49', icon: 'flags/germany-24.png' }
                          ]}
                        />
                      </CcInput>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="19" defaultValue={
`<CcInput
  type="tel"
  pattern="[0-9]{2}[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}"
  label="Phone number"
  placeholder="00 000 00 00"
  cssClass="prefixed"
>
  <CcSelectBox
    cssClass="prefix"
    value="41"
    previewIndex="1"
    items={[
      { label: ['Switzerland', '+41'], value: '41', icon: 'flags/switzerland-24.png' },
      { label: ['Austria', '+43'], value: '43', icon: 'flags/austria-24.png' },
      { label: ['United Kingdom', '+44'], value: '44', icon: 'flags/united-kingdom-24.png' },
      { label: ['Denmark', '+45'], value: '45', icon: 'flags/denmark-24.png' },
      { label: ['Germany', '+49'], value: '49', icon: 'flags/germany-24.png' }
    ]}
  />
</CcInput>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Input (with label) as prefix</h4>
                      <CcInput name="city" label="City" cssClass="prefixedWithLabel">
                        <CcInput name="postcode" label="Postcode" cssClass="prefixWithLabel" />
                      </CcInput>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly defaultValue={
`<CcInput name="city" label="City" cssClass="prefixedWithLabel">
  <CcInput name="postcode" label="Postcode" cssClass="prefixWithLabel" />
</CcInput>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_list">List</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Simple list</h4>
                      <CcList
                        items={[
                          'List item 1',
                          'List item 2',
                          'List item 3'
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="7" defaultValue={
`<CcList
  items={[
    'List item 1',
    'List item 2',
    'List item 3'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Dotted vertical line between bullets</h4>
                      <CcList
                        cssClass="dotted"
                        items={[
                          'List item 1',
                          'List item 2',
                          'List item 3'
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="7" defaultValue={
`<CcList
  cssClass="dotted"
  items={[
    'List item 1',
    'List item 2',
    'List item 3'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Checkmark icons and dotted line</h4>
                      <CcList
                        cssClass={['checklist','dotted']}
                        items={[
                          'List item 1',
                          'List item 2',
                          'List item 3'
                        ]}
                      />
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="7" defaultValue={
`<CcList
  cssClass={['checklist','dotted']}
  items={[
    'List item 1',
    'List item 2',
    'List item 3'
  ]}
/>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_tooltip">Tooltip</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <div>
                        Tooltip
                        <CcTooltip>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </CcTooltip>
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="3" defaultValue={
`<CcTooltip>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
</CcTooltip>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_modal">Modal dialog</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcModal
                        id="demo-dialog"
                        title="Modal title"
                        show={this.state.modals['demo-dialog'] && this.state.modals['demo-dialog'].show}
                        handleClose={this.hideModal}
                        backdropClose={true}
                      >
                        Modal content elements
                      </CcModal>
                      <a href="/" onClick={this.showModal} data-modal="demo-dialog">Open modal</a>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="11" defaultValue={
`<CcModal>
  id="demo-dialog"
  title="Modal title"
  show={this.state.modals['demo-dialog'] && this.state.modals['demo-dialog'].show}
  handleClose={this.hideModal}
  backdropClose={true}
>
  Modal content elements
</CcModal>
<a href="/" onClick={this.showModal} data-modal="demo-dialog">Open modal</a>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_panel">Panel</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Simple panel with icon</h4>
                      <CcPanel icon="protect-2" customCssClass={style.demoPanel}>
                        Panel content
                      </CcPanel>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="2" defaultValue={
`<CcPanel icon="protect-2">
  Panel content
</CcPanel>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Panel with title and content</h4>
                      <CcPanel title="Title" customCssClass={style.demoPanel}>
                        Optional content
                      </CcPanel>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="2" defaultValue={
`<CcPanel title="Title"> 
  Optional content
</CcPanel>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Expandable panel</h4>
                      <CcPanel title="Title" expandable customCssClass={style.demoPanel}>
                        Some content
                      </CcPanel>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="2" defaultValue={
`<CcPanel title="Title" expandable>
  Some content
</CcPanel>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Expandable with initial expanded state</h4>
                      <CcPanel title="Title" expanded customCssClass={style.demoPanel}>
                        Some content
                      </CcPanel>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="2" defaultValue={
`<CcPanel title="Title" expanded>
  Some content
</CcPanel>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <h3 id="cc_slider">Slider</h3>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Single visible item with default settings</h4>
                      <div className={style.demoSlider}>
                        <CcSlider>
                          {
                            ['One','Two','Three'].map((item, ix) =>
                              <div key={ix}>
                                <div style={{ textAlign: 'center', padding: '32px 16px 16px', margin: '4px', backgroundColor: '#dde8fc' }}>
                                  <h4>{item}</h4>
                                </div>
                              </div>
                            )
                          }
                        </CcSlider>
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcSlider>
  {
    ['One','Two','Three'].map((item, ix) =>
      <div key={ix}>
        <div style={{ textAlign: 'center', padding: '32px 16px 16px', margin: '4px', backgroundColor: '#dde8fc' }}>
          <h4>{item}</h4>
        </div>
      </div>
    )
  }
</CcSlider>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>Multiple visible items with custom settings</h4>
                      <div className={style.demoSlider}>
                        <CcSlider settings={{ slidesToShow: 2, slidesToScroll: 2, arrows: false, dots: true }}>
                          {
                            ['One','Two','Three','Four','Five','Six'].map((item, ix) =>
                              <div key={ix}>
                                <div style={{ textAlign: 'center', padding: '32px 16px 16px', margin: '4px', backgroundColor: '#dde8fc' }}>
                                  <h4>{item}</h4>
                                </div>
                              </div>
                            )
                          }
                        </CcSlider>
                      </div>
                      <div className={style.note}>
                        List of all available settings with description and examples is <a target="_blank" href="https://kenwheeler.github.io/slick/"><strong>here</strong></a>.
                      </div>
                    </CcFieldGroup>
                    <CcFieldGroup>
                      <textarea readOnly rows="10" defaultValue={
`<CcSlider settings={{ slidesToShow: 2, slidesToScroll: 2, arrows: false, dots: true }}>
  {
    ['One','Two','Three','Four','Five','Six'].map((item, ix) =>
      <div key={ix}>
        <div style={{ textAlign: 'center', padding: '32px 16px 16px', margin: '4px', backgroundColor: '#dde8fc' }}>
          <h4>{item}</h4>
        </div>
      </div>
    )
  }
</CcSlider>`
                      } />
                    </CcFieldGroup>
                  </CcFieldRow>
                  
                </CcFieldSet>
              </div>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default DemoComponents;