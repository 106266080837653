import React from 'react';
import style from './input.module.css';

class CcInput extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      value: (this.props.value || '')
    };

    this.prepareDatalist();
    this.handleChange = this.handleChange.bind(this);
  }

  prepareDatalist() {
    if (Array.isArray(this.props.datalist)) {
      let defaultItem = null;

      this.datalistId = 'dlist_' + performance.now() + Math.random();

      this.datalist = this.props.datalist.map((item, ix) => {
        let rez = {};

        if (Array.isArray(item) && item.length) {
          rez.value = item[0];
          rez.label = (item.length > 1 ? item[1] : item[0]) + '';

          if (item.length > 2) {
            rez.desc = item[2];
          }

          if (this.props.value === rez.value) {
            defaultItem = rez;
          }
        }
        else if (typeof item === 'object') {
          rez = item;

          if (this.props.value === rez.value) {
            defaultItem = rez;
          }
        }
        else {
          rez.value = ix;
          rez.label = item + '';

          if (this.props.value === rez.label) {
            defaultItem = rez;
          }
        }

        return rez;
      });

      this.state.selectedItem = defaultItem;
      const matched = this.matchDatalistValue((defaultItem ? defaultItem.label : this.props.value), false, false);
      this.state.value = (defaultItem ? defaultItem.label : '');
      this.state.shadowValue = (matched === null ? '' : matched);
      this.state.datalistInvalidValue = (this.props.value && matched === null ? true : false);
    }
  }

  matchDatalistValue(data, searchByValue, setState, stateCallback) {
    let result = null;

    if (this.datalist) {
      let selItem = (data === undefined ? null : this.datalist.find(item => item[searchByValue ? 'value' : 'label'].toLowerCase() === (data + '').toLowerCase()));
      
      if (setState) {
        this.setState(
          {
            value: (selItem ? selItem.label : (searchByValue ? '' : data)),
            selectedItem: selItem,
            shadowValue: (selItem ? selItem.value : (this.props.datalistStrict ? '' : data)),
            datalistInvalidValue: (!selItem && data)
          },
          stateCallback
        );
      }
      else {
        stateCallback && stateCallback();
      }

      result = (selItem ? selItem.value : null);
    }
    else {
      stateCallback && stateCallback();
    }

    return result;
  }

  handleChange(ev) {
    this.setState(
      { value: ev.target.value },
      () => {
        this.matchDatalistValue(ev.target.value, false, true, () => {
          if (this.props.onChange) {
            this.props.onChange(ev, this.props);
          }
        });
      }
    );
  }

  reset() {
    this.setState({
      value: '',
      selectedItem: null,
      shadowValue: '',
      datalistInvalidValue: false
    });
  }

  render() {
    let cssClass = style.field;

    if (this.props.invalid || (this.props.datalistStrict && this.state.datalistInvalidValue)) {
      cssClass = style.invalid;
    }
    else if (this.props.valid) {
      cssClass = style.valid;
    }

    if (this.props.currency) {
      cssClass += ' ' + style.currency;
    }

    if (this.datalist) {
      cssClass += ' ' + style.datalist;
    }

    if (this.props.cssClass) {
      cssClass += (Array.isArray(this.props.cssClass) ? this.props.cssClass.map(item => (' ' + style[item])).join('') : ' ' + style[this.props.cssClass]);
    }

    if (this.props.customCssClass) {
      cssClass += (' ' + this.props.customCssClass);
    }

    return (
      <div className={cssClass}>
        {this.props.children}
        <input
          id={this.props.id}
          name={this.datalist ? null : this.props.name}
          type={this.props.type || 'text'}
          inputMode={this.props.inputmode}
          value={this.state.value}
          required={this.props.required}
          disabled={this.props.disabled}
          className={style.inputField}
          pattern={this.props.pattern}
          placeholder={this.props.label ? ' ' : (this.props.placeholder ? this.props.placeholder : '')}
          list={this.datalistId}
          onChange={this.handleChange}
          onFocus={(ev) => {
            this.datalist && ev.target.select();
          }}
        />
        <label className={style.label}>{this.props.label}</label>
        {
          this.props.currency &&
          <span className={style.currencyCode}>{this.props.currency}</span>
        }
        {
          this.props.placeholder && this.props.label &&
          <span className={style.placeholder}>{this.props.placeholder}</span>
        }
        {
          this.props.errorMsg &&
          <span className={style.error}>{this.props.errorMsg}</span>
        }
        {
          this.datalist &&
          <datalist id={this.datalistId}>
            {
              this.datalist.map((item, key) =>
                // Workaround for a bug in Firefox, which prevents showing the content of the value attribute in dropdown and the content of the label attribute in input
                // https://bugzilla.mozilla.org/show_bug.cgi?id=869690
                'InstallTrigger' in window ?
                  <option
                    key={key}
                    value={item.label}
                    label={item.label + (item.desc ? ' (' + item.desc + ')' : '')}
                    data-value={item.value}
                  />
                  :
                  <option
                    key={key}
                    value={item.label}
                    label={item.desc}
                    data-value={item.value}
                  />
              )
            }
          </datalist>
        }
        {
          this.datalist &&
          <input type="hidden" id={'shadow_input_' + this.datalistId} name={this.props.name} value={this.state.shadowValue} />
        }
      </div>
    );
  }
}

export default CcInput;