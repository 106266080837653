import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import { CcFieldSet, CcFieldRow, CcFieldGroup } from '../components/form/field-grid';
import CcList from '../components/common/list';
import CcCheckbox from '../components/form/checkbox';
import CcInput from '../components/form/input';
import CcRadio from '../components/form/radio';
import CcButton from '../components/form/button';

import style from './traderscard.module.css';
import demoImg from '../assets/img/dummy.jpg';

class TradersCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cardCurrency: 'chf'
    };

    this.currencyRef = React.createRef();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="/" showPlatform={this.props.showPlatform} />
        <main className="main">
          <div className={style.container}>
            <div className="content">
              <h1>{t('demo.tradersCard.pageTitle')}</h1>
              <h2>{t('demo.tradersCard.pageSubtitle')}</h2>
              <div className={style.card}>
                <img src={demoImg} alt="" />
              </div>
              <CcList
                cssClass="checklist"
                customCssClass={style.hlights}
                items={[
                  t('demo.tradersCard.hlight1'),
                  t('demo.tradersCard.hlight2'),
                  t('demo.tradersCard.hlight3'),
                ]}
              />
              <form autoComplete="off">
                <CcFieldSet>

                  <div className={style.skip}>
                    <a href="confirm">{t('common.skipStep')}</a>
                  </div>

                  <h3>{t('demo.tradersCard.additionalServices')}</h3>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="icorner">
                        <h5>{t('demo.tradersCard.iCorner')}</h5>
                        <span className={style.serviceDesc}>{t('demo.tradersCard.iCornerDesc')}</span>
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <h4>{t('demo.tradersCard.travelInsurance')}</h4>
                      {t('demo.tradersCard.travelInsuranceDesc')}
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="individual">
                        <h5>{t('demo.tradersCard.individualCoverage')}</h5>
                        <span className={style.serviceDesc}>
                          <Trans i18nKey="demo.tradersCard.individualCoverageDesc" components={{ BR: <br /> }} />
                        </span>
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcCheckbox name="family">
                        <h5>{t('demo.tradersCard.familyCoverage')}</h5>
                        <span className={style.serviceDesc}>
                          <Trans i18nKey="demo.tradersCard.familyCoverageDesc" components={{ BR: <br /> }} />
                        </span>
                      </CcCheckbox>
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <div className={style.notice}>{t('demo.tradersCard.notice')}</div>
                    </CcFieldGroup>
                  </CcFieldRow>

                </CcFieldSet>

                <h3>{t('demo.tradersCard.requiredInfo')}</h3>
                <div>{t('demo.tradersCard.chooseCurrency')}</div>

                <CcFieldSet>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput
                        name="currency"
                        label={t('financialInfo.currency')}
                        value="chf"
                        datalist={[
                          { value: 'usd', label: 'USD – U.S. dollar' },
                          { value: 'eur', label: 'EUR – Euro' },
                          { value: 'chf', label: 'CHF – Swiss Franc' },
                          { value: 'czk', label: 'CZK – Czech crown' },
                          { value: 'dkk', label: 'DKK – Danish krone' },
                          { value: 'gbp', label: 'GBP – Pound sterling' },
                          { value: 'uah', label: 'UAH – Ukrainian hryvnia' }
                        ]}
                        datalistStrict
                        ref={this.currencyRef}
                        onChange={() => this.setState({ cardCurrency: (this.currencyRef.current?.state.selectedItem?.value || 'chf') })}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <div>{t('demo.tradersCard.rentingOrOwning')}</div>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcRadio
                        name="home"
                        options={[
                          t('demo.tradersCard.renting'),
                          t('demo.tradersCard.owning')
                        ]}
                      />
                    </CcFieldGroup>
                  </CcFieldRow>

                  <CcFieldRow>
                    <CcFieldGroup>
                      <CcInput name="home-cost" label={t('demo.tradersCard.homeAnnualCost')} currency={this.state.cardCurrency} />
                    </CcFieldGroup>
                  </CcFieldRow>

                </CcFieldSet>

                <div className={style.convertor}>
                  <a href="/">
                    <i className="cticon-dollar-euro-exchange" />
                    {t('financialInfo.currencyConvertor')}
                  </a>
                </div>

                <div className={style.btnHolder}>
                  <CcButton text={t('demo.tradersCard.request')} link="/" />
                  <div>
                    <a href="/">{t('demo.tradersCard.continue')}</a>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(TradersCard);