import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CcHeader from '../components/common/header';
import CcFooter from '../components/common/footer';
import CcButton from '../components/form/button';

import style from './traderscard-intro.module.css';
import visaImg from '../assets/img/ct-visa-rt.png';

class TradersCard extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <CcHeader prev="funds-origin" showPlatform={this.props.showPlatform} step="10" />
        <main className="main">
          <div className={style.container}>
            <div className={style.content}>
              <div>
                <h1>{t('tradersCardIntro.pageTitle')}</h1>
                <h2>{t('tradersCardIntro.pageSubtitle')}</h2>
                <div className={style.card}>
                  <img src={visaImg} alt="" />
                </div>
                <div className={style.hlights}>
                  <div>
                    <i className="cticon-geography"></i>
                    {t('tradersCardIntro.hlight1')}
                  </div>
                  <div>
                    <i className="cticon-mobile-taxi-service"></i>
                    {t('tradersCardIntro.hlight2')}
                  </div>
                  <div>
                    <i className="cticon-relaxed-working-with-a-laptop"></i>
                    {t('tradersCardIntro.hlight3')}
                  </div>
                </div>
                <div className={style.btnHolder}>
                  <CcButton text={t('tradersCardIntro.addCard')} link="traderscard" />
                  <div className={style.skip}>
                    <a href="confirm">{t('tradersCardIntro.skip')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <CcFooter />
      </>
    );
  }
}

export default withTranslation()(TradersCard);